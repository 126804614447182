/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from "react";
import "./ProjectCard.less";
import {
  IconChevronDown,
  IconChevronUp,
  IconFileSymlink,
  IconCircleCheck,
  IconLoader,
  IconArchive,
  IconCirclePlus,
  IconAccessible,
  IconAccessPointOff,
  IconHeartPlus,
} from "@tabler/icons";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import ResponseCard from "../ResponseCard/ResponseCard";
import { format } from "date-fns";
import Image from "../../common/Image/Image";
import {
  useCreateProject,
  useDeleteProject,
  useProfsListBySpec,
} from "../../../gql/hooks/useProjects";
import Popup from "../../common/Popup/Popup";
import ru from "date-fns/locale/ru";
import { useAppContext } from "@/app/providers/AppProvider";
import ButtonSimple, {
  IconComponent,
} from "../../common/ButtonSimple/ButtonSimple";
import AddStudentPopup from "../../common/AddStudentPopup/AddStudentPopup";
import useRedirect from "@/legacy/hooks/useRedirect";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";
import { isNewResponse } from "@/shared/lib/utils/project-activity";
import useDownloadS3Test from "@/legacy/hooks/useDownloadS3Test/useDownloadS3Test";
import {
  setOppenedCard,
  setCurrentProject,
} from "@/legacy/redux/ProjectsSlice/ProjectSlice";
import { handleAcceptAllResponses } from "@/legacy/components/Projects/lib/HandleAllResponses";
import { Button } from "@/shared";

function ProjectCard({
  type,
  place,
  i,
  isParticipate,
  historyRef,
  participateData,
  filter,
}: any) {
  // const [isOpen, setIsOpen] = useState(false);
  const [resInt, setResInt] = useState(0);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  // const [collapseHeight, setCollapseHeight] = useState(null);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const me = useSelector((state) => state.auth.me);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const stud = useSelector((state) => state.prof.studentInfo);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const oppenedCard = useSelector((state) => state.proj.oppenedCard);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const dispatch = useDispatch();
  const redirect = useRedirect();

  const role = useGetRole();

  const [paControl] = useCreateProject();
  const [paDeleteProject] = useDeleteProject();

  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studInfo = useSelector((state) => state.prof.studentInfo);
  const studentNamespec = filter?.student_namespec;
  const [isAddStudent, setIsAddStudent] = useState(false);

  const fileId = i?.data?.fileId;

  const { addNotification, openToast } = useAppContext();

  const { loading, errorMessage, downloadUrl } = useDownloadS3Test(fileId);

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef && historyRef === i.req_id) {
      // console.log('myReeef', myRef,  historyRef)
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      myRef.current.scrollIntoView({
        behavior: "smooth",
      });
      dispatch(setOppenedCard(i.req_id));
    }
  }, [myRef, historyRef, i.req_id, dispatch]);
  /*  var block = document.querySelector('.test');

   useEffect(() => {
     setCollapseHeight(block.clientHeight)
   }, []) */

  const {
    name,
    supervisor,
    dateStart,
    dateEnd,
    projectType,
    namespec,
    consultant,
    customer,
    responses,
    nameProf,
    workType,
    courses,
    customerType,
    semester,
    foreignCustomer,
  } = i.data;

  function goToProject() {
    dispatch(setCurrentProject(i));
    redirect("/project");
  }

  const handleDownload = () => {
    if (downloadUrl) {
      window.location.href = downloadUrl;
    }
  };

  function handleParticipate(action: any) {
    const newResponces = isNewResponse(
      me,
      stud,
      responses,
      action,
      studentNamespec
    );
    const params = {
      event: "change",
      payload: {
        req_id: i.req_id,
        args: {
          status: "ready",
          data: {
            ...i.data,
            responses: newResponces,
          },
        },
        action: "update",
      },
    };
    paControl({ variables: { params } })
      .then((res) => {
        openToast?.({ message: "Отклик отправлен" });
      })
      .then(() => {
        if (action === "send") {
          addNotification?.(
            "Проектная деятельность",
            [supervisor.id],
            {
              message: "У Вас новые заявки от участников.",
              reqId: i.req_id,
            },
            {
              path: "/my-projects",
              from: "Проектная деятельность",
            }
          );
        } else {
          addNotification?.(
            "Проектная деятельность",
            [supervisor.id],
            {
              message: `${me.data.full_name} отменил(а) заявку.`,
              reqId: i.req_id,
            },
            {
              path: "/notifications",
              from: "Проектная деятельность",
            }
          );
        }
      })
      .catch((e) => {
        console.log("onCreate error", e);
      });
  }
  function onModerParticipate(resNew: any) {
    const newResponces: any = [];
    resNew.map((i: any) => {
      if (
        responses.some(
          (old: any) => old.id === i.id && old.approved === "approved"
        )
      ) {
        return null;
      }
      newResponces.push({
        name: i.full_name,
        id: i.id,
        birth: i.birth,
        spec_info: [
          {
            ...i.studentInfo,
            course: i.course,
          },
        ],
        approved: "send",
        created: new Date(Date.now()),
      });

      return null;
    });

    i.data.responses.map((i: any) => newResponces.push(i));

    const params = {
      event: "change",
      payload: {
        req_id: i.req_id,
        args: {
          status: "ready",
          data: {
            ...i.data,
            responses: newResponces,
          },
        },
        action: "update",
      },
    };
    paControl({ variables: { params } })
      .then((res) => {
        openToast?.({ message: "Отклик отправлен" });
      })
      .then(() => {
        addNotification?.(
          "Проектная деятельность",
          [supervisor.id],
          {
            message: "У Вас новые заявки от участников.",
            reqId: i.req_id,
          },
          {
            path: "/my-projects",
            from: "Проектная деятельность",
          }
        );
        setIsAddStudent(false);
      })
      .catch((e) => {
        console.log("onCreate error", e);
      });
  }

  const projInfo = [
    {
      name: "Краткое описание проекта (цели, задачи, планируемые результаты)",
      value: i.data.description.length > 0 ? i.data.description : "--",
    },
    {
      name: "Требования к студентам, участникам проекта (направление подготовки, специальность, личные характеристики)",
      value: i.data.requirments.length > 0 ? i.data.requirments : "--",
    },
    {
      name: "Дополнительная информация",
      value: i.data.additional.length > 0 ? i.data.additional : "--",
    },
    {
      name: "Форма отчета",
      value: i.data.reportForm.length > 0 ? i.data.reportForm : "--",
    },
    {
      name: "Контактный телефон руководителя",
      value: i.data.mobile ? i.data.mobile : "--",
    },
    {
      name: "Email руководителя",
      value: i.data.email ? i.data.email : "--",
    },
  ];

  function deleteProject() {
    const params = {
      req_id: i.req_id,
      event: "paWipe",
    };
    paDeleteProject({ variables: { params } })
      .then(() =>
        addNotification?.(
          "bell",
          [
            "303d607e-e08e-11eb-bc1f-005056aa8995",
            "a92f5442-313c-11e6-80bc-7824af85d229",
          ],
          {
            message: `Удален проект ${name}, ID: ${i.req_id} пользователем: ${me.data.full_name} гуид: ${me.data.guid}`,
          },
          {
            path: "/moderate-applications",
            from: "Проектная деятельность",
          }
        )
      )
      .then((res) => setIsDeletePopup(false))
      .then((res) => openToast?.({ message: "Проект удален" }))
      .catch((e) => console.log(e));
  }

  function handleCardOpen() {
    if (oppenedCard === i.req_id) {
      dispatch(setOppenedCard(null));
    } else {
      dispatch(setOppenedCard(i.req_id));
    }
  }

  useEffect(() => {
    let participants = 0;
    i?.data?.responses.forEach((i: any) => {
      if (i?.approved === "approved" || i?.approved === "send") {
        participants += 1;
      }
    });
    setResInt(participants);
  }, [i]);

  function useProfsBySpec() {
    const condition = namespec.join(",") || "0";
    const res = useProfsListBySpec(condition);
    if (!res.loading) {
      return res.data?.nameprofsList;
    }
    return [];
  }
  const specsWithProfs = useProfsBySpec();

  const userResponses = useMemo(() => {
    return responses.filter((i: any) => i?.id === me.data.guid);
  }, [responses, me.data.guid]);

  const handleStatus = () => {
    let userRes: any = false;
    // eslint-disable-next-line array-callback-return
    if (userResponses.some((i: any) => i.approved === "approved")) {
      userRes = "Участвую в проекте";
    } else if (userResponses.some((i: any) => i.approved === "send")) {
      userRes = "Подана заявка на участие";
    } else if (userResponses.some((i: any) => i.approved === "rejected")) {
      userRes = "Заявка на участие отклонена";
    } else if (userResponses.some((i: any) => i.approved === null)) {
      return false;
    } else {
      userResponses?.length > 0
        ? (userRes = "Подана заявка на участие")
        : (userRes = false);
    }
    return userRes;
  };
  // console.log(format( new Date (dateStart.replaceAll('-', ',')), 'd MMMM yyyy', {locale: ru}))
  const validateParticipation = () => {
    const participateAllowed = () => {
      let userRes = true;
      responses.map((i: any) => {
        if (i.id === me.data.guid) {
          if (i.approved === "approved" || i.approved === "send") {
            userRes = false;
            return true;
          }
        }
        return true;
      });

      return userRes;
    };

    const handleCourse = () => {
      const coursesArray = Array.isArray(courses) ? courses : [courses];

      if (coursesArray.length > 0) {
        const isVKR = i.data?.workType === "ВКР";
        const isCourseWork = i.data?.workType === "Курсовая работа";
        const isMasterStudent = studInfo.some(
          (item: { elevel: string }) => item.elevel === "Магистр"
        );

        return studInfo.some((item: { course: any }) => {
          const currentCourse = Number(item?.course);
          return (
            coursesArray.includes(currentCourse) ||
            coursesArray.includes(currentCourse + 1) ||
            (isVKR &&
              (coursesArray.includes(4) ||
                coursesArray.includes(5) ||
                coursesArray.includes(6))) ||
            (isCourseWork && coursesArray.includes(currentCourse)) ||
            (isMasterStudent && coursesArray.includes(currentCourse))
          );
        });
      }

      return false;
    };

    const handleSpec = () => {
      if (i.data.namespec?.length > 0) {
        return studInfo.some((item: any) =>
          i.data.namespec.includes(item?.namespec)
        );
      }
      return true;
    };

    const handleProf = () => {
      if (!(nameProf?.length > 0)) {
        return true;
      }
      if (namespec?.length > 0 && specsWithProfs?.length > 0) {
        const profsWithFilter = namespec.map((item: any) => {
          const specWithProfs = specsWithProfs.find(
            (x: any) => x.spec === item
          );
          const profsValue = specWithProfs.profs.map((x: any) => x.value);
          if (nameProf?.length > 0) {
            const intersection = nameProf.filter((x: any) =>
              profsValue.includes(x)
            );
            if (intersection?.length > 0) {
              return {
                spec: item,
                profsValue: intersection,
              };
            }
          }
          return {
            spec: item,
            profsValue,
          };
        });
        if (
          studInfo.some(
            (x: any) =>
              [
                "40.03.01 - Юриспруденция",
                "40.05.04 - Судебная и прокурорская деятельность",
                "49.03.02 - Физическая культура для лиц с отклонениями в состоянии здоровья (адаптивная физическая культура)",
                "54.03.01 - Дизайн",
              ].includes(x?.namespec) && !x.nameprof
          )
        ) {
          return true;
        }
        if (profsWithFilter?.length > 0) {
          const profsArray: any[] = [];
          profsWithFilter.forEach((x: any) => {
            if (x.profsValue?.length > 0) {
              x.profsValue.forEach((y: any) => {
                profsArray.push(y);
              });
            }
          });
          return studInfo.some((x: any) => profsArray.includes(x?.nameprof));
        }
      }
      return false;
    };

    if (
      (role === "student" || role === "student-rakus") &&
      isParticipate &&
      i.status !== "completed" &&
      i.status !== "archive" &&
      resInt < i.data.participants &&
      participateAllowed() &&
      handleCourse() &&
      handleSpec() &&
      handleProf()
    ) {
      return true;
    }
    return false;
  };

  const handleSendedPerSemester = () => {
    const sendedInCurrentSemester = participateData.filter((i: any) => {
      if (i.id === me.data.guid) {
        return i.semester === semester;
      }
    });
    return sendedInCurrentSemester.length > 0;
  };
  console.log("responses", responses);
  return (
    /* showStudent() && */ <div
      className="projectContainer"
      key={i.req_id}
      id={i.req_id}
      ref={myRef}
    >
      <div className={clsx(i.status === "archive" && "layer")}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {!!customerType && customerType !== "Университет" ? (
          <div className="projectCategoryForeign">{customerType}</div>
        ) : (
          <div className="projectCategory">Университет</div>
        )}
        <h2 className="title" onClick={handleCardOpen}>
          {name}
        </h2>
        {oppenedCard === i.req_id ? (
          <IconChevronUp
            size={35}
            color="#C5C5C5"
            onClick={handleCardOpen}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <IconChevronDown
            size={35}
            color="#C5C5C5"
            onClick={handleCardOpen}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      <div className="teacher">
        <Image
          id={
            supervisor?.id
          } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */
        />
        <div>
          <p className="subtitle">Руководитель</p>
          <p className="value">{supervisor?.full_name}</p>
        </div>
        {workType && <h3 className="workTypeTitle">{workType}</h3>}
      </div>
      <div className="border" />
      <div
        className={clsx(
          oppenedCard === i.req_id && "general",
          oppenedCard !== i.req_id && "projectFull"
        )}
      >
        <div className="aboutProject">
          <p className="subtitle">Сроки реализации</p>
          <p className="value">{`${
            format(new Date(dateStart), "dd MMMM yyyy", { locale: ru }).slice(
              0,
              3
            ) +
            format(new Date(dateStart), "dd MMMM yyyy", {
              locale: ru,
            })[3].toUpperCase() +
            format(new Date(dateStart), "dd MMMM yyyy", { locale: ru }).slice(4)
          } - ${
            format(new Date(dateEnd), "dd MMMM yyyy", { locale: ru }).slice(
              0,
              3
            ) +
            format(new Date(dateEnd), "dd MMMM yyyy", {
              locale: ru,
            })[3].toUpperCase() +
            format(new Date(dateEnd), "dd MMMM yyyy", { locale: ru }).slice(4)
          }`}</p>
          <p className="subtitle">Тип проекта</p>
          <p className="value">{projectType}</p>
          <div className="part">
            <p className="amount">{`Студентов ${resInt}/${i.data.participants}`}</p>
            <div className="scale">
              <div
                style={{ width: `${(100 / i.data.participants) * resInt}%` }}
              ></div>
            </div>
          </div>
          <ul>
            {projInfo.map((i, index) => (
              <li key={index}>
                <p className="subtitle">{i.name}</p>
                <p className="value">{i.value}</p>
              </li>
            ))}
          </ul>
          <p className="subtitle">Направления</p>
          {namespec.length === 0 && <p className="value">Любое направление</p>}
          {namespec.length > 0 && (
            <ul>
              {namespec.map((i: any, index: any) => (
                <li key={index}>
                  <div className="prof">
                    <p className="value">{i}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {nameProf ? (
            <>
              <p className="subtitle">Профили</p>
              {nameProf.length === 0 && <p className="value">Любой профиль</p>}
              {nameProf.length > 0 && (
                <ul>
                  {nameProf.map((i: any, index: any) => (
                    <li key={index}>
                      <div className="prof">
                        <p className="value">{i}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <></>
          )}
          <p className="subtitle">Консультанты</p>
          <ul className="consalts">
            {consultant.map((i: any, index: any) => (
              <li key={index}>
                <div>
                  <Image
                    id={
                      i.id
                    } /* style={{width: "24px", height: "24px", borderRadius: "20px"}} */
                  />
                  {i.full_name}
                </div>
              </li>
            ))}
          </ul>
          {courses && (
            <>
              <p className="subtitle">Курс</p>
              <ul className="consalts">
                {courses.isArray ? (
                  courses.map((i: any, index: any) => (
                    <li key={index}>
                      <div>{`${i} курс`}</div>
                    </li>
                  ))
                ) : (
                  <li>
                    <div>{`${courses} курс`}</div>
                  </li>
                )}
              </ul>
            </>
          )}
          <p className="subtitle">Заказчик</p>
          {customerType && customerType !== "Внешний заказчик" && (
            <div className="customer">
              <img
                src="https://i.pinimg.com/736x/70/5b/bb/705bbb820c7332b04d619f7536645753.jpg"
                alt="logo"
              />
              {customer}
            </div>
          )}
          {customerType && customerType === "Внешний заказчик" && (
            <div className="customer">
              <img
                src="https://i.pinimg.com/736x/70/5b/bb/705bbb820c7332b04d619f7536645753.jpg"
                alt="logo"
              />
              {foreignCustomer}
            </div>
          )}
          {fileId && (
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : errorMessage ? (
                <p>Error: {errorMessage}</p>
              ) : (
                <button className="downloadButton" onClick={handleDownload}>
                  Скачать подтверждающий документ
                </button>
              )}
            </div>
          )}
          {(role === "pa_moder" || place === "my") && role !== "student" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="value" style={{ margin: "30px 0" }}></p>

                {role === "pa_moder" && (
                  <ButtonSimple
                    value={
                      <IconComponent
                        text="Добавить студента"
                        Icon={IconCirclePlus}
                      />
                    }
                    active
                    onClickHandler={() => setIsAddStudent(!isAddStudent)}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              >
                <p className="value" style={{ margin: "30px 0" }}>
                  Отклики
                </p>
                {(role === "pa_moder" || role === "worker") && (
                  <Button
                    addonLeft={
                      <span className="text">{"Одобрить все отклики"}</span>
                    }
                    addonRight={<IconHeartPlus />}
                    className={"button"}
                    variant="filled"
                    size="m"
                    onClick={() =>
                      handleAcceptAllResponses(
                        responses,
                        paControl,
                        openToast,
                        addNotification,
                        i,
                        i.req_id
                      )
                    }
                    disabled={loading ? true : false}
                  />
                )}
              </div>
              {responses.map((item: any, index: any) =>
                item ? (
                  <ResponseCard
                    // accpetAll={handleAll}
                    resInt={resInt}
                    project={i}
                    req_id={i.req_id}
                    responses={responses}
                    response={item}
                    key={`${item.id}${index}`}
                  />
                ) : null
              )}
            </div>
          )}
        </div>
        <div className="deadLines">
          {/* {type === "active" ? <><p className="subtitle">Срок подачи заявок до</p>
              <p className="valueBig">06 сентября 2021</p></> : <></>} */}
          {handleStatus() && (
            <button style={{ border: "1px solid  #FF5E5E" }}>
              {handleStatus()}
            </button>
          )}
          {i.status === "ready" && i.data.participants - resInt < 0 && (
            <button>
              Выполнение проекта
              <IconLoader color="#7F8191" style={{ marginLeft: "13px" }} />
            </button>
          )}
          {i.status === "archive" && (
            <button>
              Проект в архиве
              <IconArchive color="#7F8191" style={{ marginLeft: "13px" }} />
            </button>
          )}
          {i.data.participants - resInt > 0 &&
            i.status !== "archive" &&
            i.status !== "completed" && (
              <button>
                Прием заявок
                <IconFileSymlink
                  color="#7671DD"
                  style={{ marginLeft: "13px" }}
                />
              </button>
            )}
          {i.status === "completed" && (
            <button style={{ background: "#7671DD", color: "white" }}>
              {"Проект завершен"}
              {<IconCircleCheck color="white" style={{ marginLeft: "13px" }} />}
            </button>
          )}
          {validateParticipation() && !handleSendedPerSemester() && (
            <button
              className={clsx("second")}
              onClick={() => handleParticipate("send")}
            >
              Участвовать в проекте
            </button>
          )}
          {String(handleStatus()) === "Подана заявка на участие" &&
            (role === "student" || role === "student-rakus") &&
            i.status !== "archive" && (
              <button
                className={clsx("second")}
                onClick={() => handleParticipate("reject")}
              >
                Отменить поданную заявку
              </button>
            )}
        </div>
        {oppenedCard === i.req_id ? <></> : <div className="vigor" />}
      </div>
      <div className="cardFooter">
        {/* <ul className="hashtegs">
            <li><div>#страноведение</div></li>
            <li><div>#страноведение</div></li>
            <li><div>#страноведение</div></li>
          </ul> */}
        {(role === "pa_moder" || place === "my") && (
          <button className="projectWork" onClick={goToProject}>
            Работать над проектом
          </button>
        )}
        {role === "pa_moder" /* || (place === "my" && role === 'worker') */ && (
          <button
            className="projectWork"
            style={{ background: "#FF4D4D" }}
            onClick={() => setIsDeletePopup(!isDeletePopup)}
          >
            Удалить проект
          </button>
        )}
      </div>

      <Popup
        isOpen={isDeletePopup}
        handlePopup={() => setIsDeletePopup(!isDeletePopup)}
      >
        <div className="errorWrap">
          <h3 className="errorTitle">Удалить этот проект?</h3>
          <p className="confirm">Подтвердите удаление.</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ButtonSimple
              styles={{ width: "28%" }}
              variant="alone"
              active={false}
              value="Отмена"
              onClickHandler={() => setIsDeletePopup(!isDeletePopup)}
            />
            <ButtonSimple
              styles={{ width: "69%" }}
              variant="alone"
              active={true}
              value="Удалить"
              onClickHandler={deleteProject}
            />
          </div>
        </div>
      </Popup>

      <AddStudentPopup
        handlePopup={setIsAddStudent}
        isOpen={isAddStudent}
        handleSubmit={onModerParticipate}
      />
    </div>
  );
}

export default ProjectCard;
