import ButtonSimple from "@/legacy/components/common/ButtonSimple/ButtonSimple";
import { InputText } from "@/shared";
import { Tooltip } from "antd";
import cls from '../resultBlock.module.less';

export const renderGraduatingSemesterButtons = (studentResults:any, id:any, formValues:any, index:any, handleInputChange:any, onUpdateStudentWorkStatus:any) => {
  if (studentResults?.files?.length) {
    if (studentResults.status !== 'pending' && studentResults.status !== 'rejected') {
      return (
        <>
          <InputText
            className={cls.inputResult}
            type="text"
            value={`Оценка за работу: ${studentResults.status}`}
            disabled={true}
          />
          <Tooltip title="Отклонить работу">
            <span>
              <ButtonSimple
                value={studentResults?.status === 'rejected' ? 'Отклонено' : 'Отклонить'}
                variant="groupe"
                onClickHandler={() => onUpdateStudentWorkStatus(id, 'rejected')}
                active={studentResults?.status !== 'rejected'}
                isDisabled={studentResults?.status === 'rejected'}
              />
            </span>
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <InputText
            className={cls.inputResult}
            type="number"
            value={formValues[index] ?? ''}
            title='Оценка'
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
          <Tooltip title="Отправить оценку">
            <span>
              <ButtonSimple
                className={cls.button}
                value="Отправить"
                variant="transparent"
                onClickHandler={() => {
                  onUpdateStudentWorkStatus(id, formValues[index]);
                }}
                isDisabled={!formValues[index]}
              />
            </span>
          </Tooltip>
        </>
      );
    }
  } else {
    return studentResults?.status && <div>{`Оценка за работу: ${studentResults.status}`}</div>;
  }
};

export const renderNonGraduatingSemesterButtons = (studentResults:any, id:any, onUpdateStudentWorkStatus:any) => {
  return (
    <>
      <Tooltip title="Подтвердить работу">
        <span>
          <ButtonSimple
              value={studentResults?.status === 'approved' ? 'Подтверждено' : 'Подтвердить'}
            variant="groupe"
            onClickHandler={() => onUpdateStudentWorkStatus(id, 'approved')}
            active={studentResults?.status !== 'approved'}
            isDisabled={!studentResults?.files?.length || studentResults.status === 'approved'}
          />
        </span>
      </Tooltip>
      <Tooltip title="Отклонить работу">
        <span>
          <ButtonSimple
            value={studentResults?.status === 'rejected' ? 'Отклонено' : 'Отклонить'}
            variant="groupe"
            onClickHandler={() => onUpdateStudentWorkStatus(id, 'rejected')}
            active={studentResults?.status !== 'rejected'}
            isDisabled={studentResults?.status === 'rejected'}
          />
        </span>
      </Tooltip>
    </>
  );
};
