import "./GetReferencePopup.less";
import clsx from "clsx";

function GetReferencePopup({
  title,
  items,
  isOpen,
  onSpecItemClick,
  onRefTypeClick,
  onAuthorityClick,
  setAmount,
}: any) {
  function onClick(i: any) {
    if (title === "Выберите специальность") {
      onSpecItemClick(i);
    } else if (title === "Выберите категорию справки") {
      onRefTypeClick(i);
    } else if (title === "Укажите место предоставления") {
      onAuthorityClick(i);
    } else {
      setAmount(i);
    }
  }

  return (
    <ul className={clsx("refPopup", !isOpen && "hidden")}>
      {items &&
        items?.map((i: any) => {
          return (
            <li
              onClick={() => onClick(i)}
              key={items.indexOf(i)}
              className="refPopupItem"
            >
              {typeof i === "string" ? i : `${i?.namespec}`}
            </li>
          );
        })}
    </ul>
  );
}

export default GetReferencePopup;
