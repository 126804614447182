import { useMemo, } from 'react'
import './RatingScale.less'
import { ResponsiveRadialBar, } from '@nivo/radial-bar'
import { Switch, } from 'antd'
import ButtonSimple from '../../common/ButtonSimple/ButtonSimple'
import { useAchievementRating, } from '@/legacy/gql/hooks/useAchievements'
import useAchievementChanged from '../../../gql/hooks/portfolio/useAchievementChanged'
import useGetPersonalRatingSettings from '../../../gql/hooks/portfolio/useGetPersonalRatingSettings'
import useChangePersonalRatingSettings from '../../../gql/hooks/portfolio/useChangePersonalRatingSettings'
import usePersonnelConfigChanged from '../../../gql/hooks/portfolio/usePersonnelConfigChanged'
import { IconArrowBarToDown, } from '@tabler/icons'
import { useSelector, } from 'react-redux'
import { URL, } from '@/shared/constants/constants'
import _ from 'lodash'

function RatingScale ({ isAdd, setIsAdd, }: any) {
  const { result: toggleState, refetch: toggleStateRefetch, } =
    useGetPersonalRatingSettings()

  const toggleStateUpdate = useChangePersonalRatingSettings()
  const { data, error, loading, refetch, } = useAchievementRating()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentId = useSelector(state => state.auth.me.data.guid)
  // @ts-expect-error TS(18046): 'state' is of type 'unknown'.
  const studInfo = useSelector(state => state.prof.studentInfo)

  useAchievementChanged(refetch)
  usePersonnelConfigChanged(toggleStateRefetch)

  const handleELevel = () => {
    return studInfo.some((item: any) => item?.elevel === 'СПО')
  }

  const rating = useMemo(() => {
    if (error) {
      return <div>....error....</div>
    } else if (loading || !data) {
      return <div>.....loading....</div>
    }

    const { available, max, position, rating, } = data.achievementRating
    const textSummory = [
      {
        title: 'Всего баллов: ',
        value: rating || 0,
      },
      {
        title: 'Сумма баллов, учитывающаяся в рейтинге: ',
        value: available || 0,
        percent: _.round((available / rating) * 100, 0) || 0,
        color: 'basic-indicators',
      },
      {
        title: 'Ожидают подтверждения: ',
        value: rating && available ? rating - available : 0,
        percent: _.round(((rating - available) / rating) * 100, 0) || 0,
        color: 'hight-performance',
      },
    ]

    const barSettings = [
      {
        id: 'confirmdPoints',
        data: [
          {
            x: 'confirmdPoints',
            y: _.round(((rating - available) / rating) * 100, 2) || 0,
          },
        ],
      },
      {
        id: 'waitingList',
        data: [
          {
            x: 'waitingList',
            y: _.round((available / rating) * 100, 2) || 0,
          },
        ],
      },
    ]

    return (
      <div
        style={ {
          display: 'flex',
          alignItems: 'center',
        } }
      >
        <div className="ratingBar">
          <ResponsiveRadialBar
            data={ barSettings }
            maxValue={ 100 }
            endAngle={ 360 }
            innerRadius={ 0.5 }
            padding={ 0.5 }
            cornerRadius={ 16 }
            colors={ [
              '#F9B754',
              '#7671DD',
            ] }
            tracksColor="#f5f6fa"
            enableTracks={ true }
            enableRadialGrid={ false }
            enableCircularGrid={ false }
            radialAxisStart={ null }
            // @ts-expect-error TS(2559): Type 'false' has no properties in common with type... Remove this comment to see the full error message
            circularAxisOuter={ false }
            // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'number'.
            labelsSkipAngle={ false }
            // @ts-expect-error TS(2322): Type 'false' is not assignable to type 'InheritedC... Remove this comment to see the full error message
            labelsTextColor={ false }
            isInteractive={ false }
            motionConfig="slow"
            transitionMode="startAngle"
            // @ts-expect-error TS(2322): Type 'boolean' is not assignable to type 'LegendPr... Remove this comment to see the full error message
            legends={ false }
            animate={ true }
          />
          <div className="textSummoryWrap">
            <p className="summoryTitle">
              Место <br />в рейтинге:
            </p>
            <div className="textSummory">{ `${ position ? position : 0 }/${ max }` }</div>
          </div>
        </div>
        <div className="summoryList">
          { textSummory.map(i => (
            <p
              key={ i.title }
              className="effective-contract__description-item"
              style={ {
                padding: '8px 10px',
                width: 'fit-content',
              } }
            >
              { i.color && (
                <span
                  className={ `indicator-circul indicator-circul_${ i.color }` }
                ></span>
              ) }
              { i.title }
              { i.value }
              { !!i.percent && ` ( ${ i.percent }% )` }
            </p>
          )) }
          {/*TODO: Раскомментировать*/}
          <div className="switchWrap">
            Доступно всем
            <Switch
            // @ts-expect-error TS(2339): Property 'is_student_rating_available_all' does n... Remove this comment to see the full error message
              checked={ toggleState?.is_student_rating_available_all }
              onChange={ () => toggleStateUpdate({
                name: 'is_student_rating_available_all',
                // @ts-expect-error TS(2339): Property 'is_student_rating_available_all' does no... Remove this comment to see the full error message
                value: String(!toggleState?.is_student_rating_available_all),
              })
              }
              disabled={handleELevel()}
            />
          </div>
          <div className="switchWrap">
            Включить себя в список рейтинга
            <Switch
            // @ts-expect-error TS(2339): Property 'is_student_rating_include_myself' does n... Remove this comment to see the full error message
              checked={ toggleState?.is_student_rating_include_myself }
              onChange={ () => toggleStateUpdate({
                name: 'is_student_rating_include_myself',
                // @ts-expect-error TS(2339): Property 'is_student_rating_include_myself' does no... Remove this comment to see the full error message
                value: String(!toggleState?.is_student_rating_include_myself),
              })
              }
              disabled={handleELevel()}
            />
          </div>
        </div>
      </div>
    )
  }, [
    data,
    error,
    loading,
    // @ts-expect-error TS(2339): Property 'is_student_rating_available_all' does no... Remove this comment to see the full error message
    toggleState?.is_student_rating_available_all,
    // @ts-expect-error TS(2339): Property 'is_student_rating_include_myself' does n... Remove this comment to see the full error message
    toggleState?.is_student_rating_include_myself,
    toggleStateUpdate,
  ])

  return (
    <div className="PortfolioScale">
      { rating }

      <div className="interactiveSide">
        <ButtonSimple
          styles={ { border: 'none', visibility: 'hidden'} }
          value="Добавить достижение"
          active={ true }
          variant="alone"
          onClickHandler={ () => setIsAdd(!isAdd) }
        />

        <div className="rate-wrapper">
          <div
            className="showRate"
            onClick={ () => window.open(`https://${ URL }/api/portfolio/xls/${ studentId }`)
            }
          >
            Выгрузить портфолио
            <IconArrowBarToDown color="#7671DD" size={ 25 } />
          </div>

          {/* <div */}
          {/*   className="showRate" */}
          {/*   onClick={ () => redirect('/portfolio-rating') } */}
          {/* > */}
          {/*   Смотреть рейтинг обучающихся */}
          {/*   <IconChartBar color="#7671DD" size={ 25 } /> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default RatingScale
