import { createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { formatISO, } from 'date-fns'
import _ from 'lodash'
import { CreateNotificationFormDates, FilterCriteriaPayload, FilterValues, ModerateNotificationState, PaginationValues, CreateNotificationFormStudentFilterValue, StudentInfo, CreateNotificationFormTargetValue, Values, } from './types'
import { FileData, } from '@/legacy/types'

const isFiles = (files: Values): files is FileData[] => Array.isArray(files)
const isDates = (dates: Values): dates is CreateNotificationFormDates => {
  if (Object.prototype.hasOwnProperty.call(dates, 'from')
    && Object.prototype.hasOwnProperty.call(dates, 'to')) {
    return true
  } return false
}
const isTargetItems = (consumers: Values): consumers is CreateNotificationFormTargetValue[] => Array.isArray(consumers)
const isTargetItem = (consumer: unknown): consumer is CreateNotificationFormTargetValue => typeof consumer === 'object'
const isStudentFilterValue = (filterObject: Values): filterObject is CreateNotificationFormStudentFilterValue => typeof filterObject === 'object'
const isStudentList = (list: Values): list is [] => Array.isArray(list)


export const CREATE_NOTIFICATION_DRAFT_FORM_NAMES = {
  type: 'Тип уведомления',
  title: 'Название',
  text: 'Текст сообщения',
  files: 'Файлы',
  publishDates: 'Даты публикации',
  isImportant: 'Важное',
  showFor: 'Кому отображать',
  studentFilter: 'Фильтр по группам студентов',
  studentList: 'Список студентов',
  isActive: 'Статус публикации',
}

const checkStudentListHasItems = (studentFilterValues: string[] = []) => {
  let isStudentFilterHasItems = false

  for (let i = 0; i < studentFilterValues.length; i++) {
    if (studentFilterValues[i].length > 0) {
      isStudentFilterHasItems = true
      break
    }
  }

  return isStudentFilterHasItems
}


const initialState: ModerateNotificationState = {
  createNotificationForm: [
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.type,
      type: 'select',
      items: [
        'Системное уведомление',
        'Объявление',
      ],
      jsonFieldName: 'type',
      value: '',
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.title,
      type: 'inputText',
      jsonFieldName: 'title',
      value: '',
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.text,
      type: 'textEditor',
      jsonFieldName: 'html',
      value: '',
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.files,
      type: 'files',
      jsonFieldName: 'files',
      value: [],
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.publishDates,
      type: 'publishDates',
      jsonFieldName: 'publish_dates',
      value: {
        from: null,
        to: null,
      },
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.isImportant,
      type: 'isImportant',
      jsonFieldName: 'is_important',
      value: false,
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor,
      type: 'checkboxList',
      jsonFieldName: 'target',
      value: [
        {
          item: 'Студенты',
          isActive: false,
        },
        {
          item: 'Преподаватели',
          isActive: false,
        },
        {
          item: 'Сотрудники',
          isActive: false,
        },
      ],
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentFilter,
      type: 'studentFilter',
      jsonFieldName: 'student_filter',
      value: {
        basis: [],
        course: [],
        semester: [],
        grade: [],
        eform: [],
        department: [],
        namespec: [],
        nameprof: [],
        group: [],
      },
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentList,
      type: 'studentList',
      jsonFieldName: 'student_list',
      value: [],
    },
    {
      name: CREATE_NOTIFICATION_DRAFT_FORM_NAMES.isActive,
      type: 'isActive',
      jsonFieldName: 'is_active',
      value: false,
    },
  ],

  draftsFilters: {
    systemNotification: {
      title: {
        type: 'inputText',
        value: '',
      },
      role: {
        type: 'select',
        items: [],
        value: null,
      },
      date: {
        type: 'calendar',
        value: {
          from: null,
          to: null,
        },
      },
      status: {
        type: 'select',
        items: [
          {
            name: 'Опубликовано',
            value: true,
          },
          {
            name: 'Не опубликовано',
            value: false,
          },
        ],
        value: {},
      },
    },

    announcementNotification: {
      title: {
        type: 'inputText',
        value: '',
      },
      role: {
        type: 'select',
        items: [],
        value: null,
      },
      date: {
        type: 'calendar',
        value: {
          from: null,
          to: null,
        },
      },
      status: {
        type: 'select',
        items: [
          {
            name: 'Опубликовано',
            value: true,
          },
          {
            name: 'Не опубликовано',
            value: false,
          },
        ],
        value: {},
      },
    },
  },

  draftsPagination: {
    systemNotification: {
      offset: 0,
      limit: 20,
    },

    announcementNotification: {
      offset: 0,
      limit: 20,
    },
  },
}


export const moderateNotificationsSlice = createSlice({
  name: 'moderateNotifications',
  initialState,
  reducers: {
    setFormType: (state, action: PayloadAction<string>) => {
      const type = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.type)

      if (type) {
        type.value = action.payload
      }
    },


    setFormTitle: (state, action: PayloadAction<string>) => {
      const title = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.title)

      if (title) {
        title.value = action.payload
      }
    },


    setFormText: (state, action: PayloadAction<string>) => {
      const text = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.text)

      if (text) {
        text.value = action.payload
      }
    },


    addFilesToList: (state, action: PayloadAction<FileData[]>) => {
      const files = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.files)

      if (files && isFiles(files.value)) {
        const values: FileData[] = files.value
        values.push(...action.payload)
      }
    },


    removeFileFromList: (state, action: PayloadAction<FileData>) => {
      const files = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.files)

      if (files && isFiles(files.value)) {
        files.value = files.value.filter((file: FileData) => Number(file.upload_id) !== Number(action.payload))
      }
    },


    setPublishDates: (state, action: PayloadAction<string[]>) => {
      const dates = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.publishDates)

      if (dates?.value && isDates(dates.value)) {
        dates.value.from = action.payload[0]
        dates.value.to = action.payload[1]
      }
    },


    setPublishTime: (state, action: PayloadAction<string>) => {
      let inputTime: string | string[] = action.payload
      if (!/:/.test(inputTime)) {
        const middle = Math.floor(inputTime?.length / 2)
        inputTime = inputTime.split('')
        inputTime.splice(middle, 0, ':')
        inputTime = inputTime.join('')
      }

      const [
        hours,
        minutes,
      ] = inputTime.split(':')

      const dates = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.publishDates)

      if (dates?.value && isDates(dates.value)) {
        const dateStart = dates.value.from
        if (dateStart !== null) {
          const newDate: Date = new Date(dateStart)
          newDate.setHours(Number(hours))
          newDate.setMinutes(Number(minutes))

          dates.value.from = formatISO(new Date(newDate))
        }
      }
    },


    setIsImortant: (state, action: PayloadAction<boolean>) => {
      const isImportant = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.isImportant)

      if (isImportant) {
        isImportant.value = action.payload
      }
    },


    setShowFor: (state, action: PayloadAction<CreateNotificationFormTargetValue>) => {
      const showFor = state.createNotificationForm.find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor)?.value

      if (showFor && isTargetItems(showFor)) {
        const targetItem = showFor.find((value: CreateNotificationFormTargetValue) => value.item === action.payload.item)

        if (targetItem) {
          targetItem.isActive = action.payload.isActive
        }
      }
    },


    addCriteriaToStudentFilter: (state, action: PayloadAction<FilterCriteriaPayload>) => {
      const addToFilter = (newElement: string, studentFilter: CreateNotificationFormStudentFilterValue, field: string) => {
        studentFilter[field].push(newElement)
      }

      const { field, newValue, } = action.payload
      const studentFilter = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentFilter)

      if (studentFilter && isStudentFilterValue(studentFilter.value)) {
        if (studentFilter.value[field].length < 1) {
          addToFilter(newValue, studentFilter.value, field)
        } else {
          if (!studentFilter.value[field].find((field: string) => field === newValue)) {
            addToFilter(newValue, studentFilter.value, field)
          }
        }
      }

      const showFor = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor)

      if (showFor && showFor.value && isTargetItems(showFor.value)) {
        const showForStudents = showFor.value.find((consumer: CreateNotificationFormTargetValue) => consumer.item === 'Студенты')

        if (studentFilter && showForStudents && isStudentFilterValue(studentFilter.value)) {
          showForStudents.filter = studentFilter.value
        }
      }
    },


    removeCriteriaFromStudentFilter: (state, action: PayloadAction<FilterCriteriaPayload>) => {
      const { field, newValue, } = action.payload

      const studentFilter = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentFilter)


      if (studentFilter) {
        if (isStudentFilterValue(studentFilter.value)) {
          studentFilter.value[field] = studentFilter.value[field]
            .filter((value: string) => value !== newValue)
        }

        const studentFilterValues = Object.values(studentFilter.value)
        const showFor = state.createNotificationForm
          .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor)


        if (showFor && showFor.value && isTargetItems(showFor.value)) {
          const showForStudents = showFor.value.find((consumer: CreateNotificationFormTargetValue) => consumer.item === 'Студенты')

          if (studentFilter && showForStudents && isStudentFilterValue(studentFilter.value)) {
            showForStudents.filter = studentFilter.value
          }
        }


        if (!checkStudentListHasItems(studentFilterValues)) {
          if (showFor && isTargetItems(showFor.value)) {
            const showForStudentsIndex = showFor.value.findIndex((consumer: CreateNotificationFormTargetValue) => consumer.item === 'Студенты')
            const newFilter = _.omitBy(showFor.value[showForStudentsIndex], (value: never, key: string) => (key === 'filter'))

            if (isTargetItem(newFilter)) {
              showFor.value[showForStudentsIndex] = newFilter
            }
          }
        }
      }
    },


    addStudentToList: (state, action: PayloadAction<StudentInfo>) => {
      const studentList = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentList)?.value as StudentInfo[]

      if (studentList) {
        studentList.push({
          id: action.payload.id,
          full_name: action.payload.full_name,
        })
      }


      const showFor = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor)?.value as CreateNotificationFormTargetValue[]

      if (showFor) {
        showFor.push({
          item: 'guids',
          isActive: true,
          guidList: [],
        })
      }

      const guidList = showFor.find((consumer: CreateNotificationFormTargetValue) => consumer.item === 'guids')?.guidList as string[]
      if (guidList) {
        guidList.push(action.payload.id)
      }
    },


    removeStudentFromList: (state, action: PayloadAction<StudentInfo>) => {
      const studentList = state.createNotificationForm
        .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentList)

      if (studentList && isStudentList(studentList.value)) {
        studentList.value = studentList.value.filter((student: StudentInfo) => student.id !== action.payload.id)
      }


      if (studentList && isStudentList(studentList.value) && studentList.value.length < 1) {
        const showFor = state.createNotificationForm
          .find(field => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.showFor)

        if (showFor && isTargetItems(showFor.value)) {
          showFor.value = showFor.value.filter((target: CreateNotificationFormTargetValue) => target.item !== 'guids')
        }
      }
    },


    resetCreateNotificationForm: state => {
      state.createNotificationForm = initialState.createNotificationForm
    },


    setCreateNotificationForm: (state, action: PayloadAction<{ filledForm: [] }>) => {
      state.createNotificationForm = action.payload.filledForm
    },


    setDefaultFiltersValues: (state, action: PayloadAction<{ roles: string[] }>) => {
      const filtersNames = Object.keys(state.draftsFilters)
      const draftsFilters = state.draftsFilters as { [key: string]: FilterValues }

      filtersNames.forEach((filterName: string) => {
        draftsFilters[filterName].role.items = action.payload.roles
      })
    },


    onChangeFilterValues: (state, action: PayloadAction<{ [key: string]: string | {} }>) => {
      const filterName = action.payload.filterName as string
      const filterKey = action.payload.filterKey as string
      const newValue = action.payload.newValue as string | {}

      const draftsFilters = state.draftsFilters as { [key: string]: FilterValues }
      const currentFilter = draftsFilters[filterName]
      const currentFilterKey = currentFilter[filterKey]

      currentFilterKey.value = newValue
    },


    onChangePaginationValues: (state, action: PayloadAction<{ [key: string]: string | number }>) => {
      const paginationName = action.payload.paginationName as string
      const paginationKey = action.payload.paginationKey as string
      const newValue = action.payload.newValue as number

      const draftsPagination = state.draftsPagination as { [key: string]: PaginationValues }
      const draftName = draftsPagination[paginationName]
      draftName[paginationKey] = newValue
    },
  },
})


export const {
  setFormType,
  setFormTitle,
  setFormText,
  addFilesToList,
  removeFileFromList,
  setPublishDates,
  setPublishTime,
  setIsImortant,
  setShowFor,
  addCriteriaToStudentFilter,
  removeCriteriaFromStudentFilter,
  addStudentToList,
  removeStudentFromList,
  resetCreateNotificationForm,
  setCreateNotificationForm,
  setDefaultFiltersValues,
  onChangeFilterValues,
  onChangePaginationValues,
} = moderateNotificationsSlice.actions

export default moderateNotificationsSlice.reducer
