/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from "react";
import "./RefData.less";
import { Row } from "antd";
import Col from "../../../Profile/Col/Col";
import { useSelector, useDispatch } from "react-redux";
import ChooseField from "../ChooseField/ChooseField";
import { placeToGet, refAmount } from "@/shared/constants/constants";
import { setHowGet } from "../../../../redux/MfcSlices/MfcSlice";
// import _ from 'lodash';
import { findContact } from "../../../../Utils/functions";
import { useCreateReference } from "../../../../gql/hooks/mfc/useReferences";
import SuccesPopup from "../../SuccesPopup/SuccesPopup";
import { openSucces } from "../../../../redux/MfcSlices/MfcSlice";
import AuthorityList from "./catalogLists/AuthorityList";
import ReferenceCategoryList from "./catalogLists/ReferenceCategoryList";
import InputTextDeprecated from "@/legacy/components/common/InputTextDeprecated/InputTextDeprecated";

function RefData({ studyData, timeLeft, setIsCounting, isCounting }: any) {
  const dispatch = useDispatch();
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const personalInfo = useSelector((state) => state.auth.me);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const authority = useSelector((state) => state.mfc.refAuthority);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const getPlace = useSelector((state) => state.mfc.howGet);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const type = useSelector((state) => state.mfc.refType);
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const amount = useSelector((state) => state.mfc.refAmount);
  const { name, surname, patronymic, date_of_birth } = personalInfo.data;
  const { namespec, department_name, eform, elevel, nameprof, course } =
    studyData;
  const tel = findContact(personalInfo.data.contacts, {
    kind_contact_information: "ТелефонМобильныйФизическиеЛица",
  });
  const email = findContact(personalInfo.data.contacts, {
    kind_contact_information: "EMailФизическиеЛица",
  });
  const emailCorp = findContact(personalInfo.data.contacts, {
    kind_contact_information: "Адрес корпоративной электронной почты",
  });
  const [disable, setDisable] = useState(false);
  const [additional, setAdditional] = useState(null);
  const [createReference] = useCreateReference();

  function handlePlaceClick(i: any) {
    dispatch(setHowGet(i));
  }

  function isChecked(i: any) {
    return getPlace === i;
  }

  const payload = {
    type: type,
    fields: {
      method: getPlace,
      count: amount,
      authority: authority,
      last_name: surname,
      first_name: name,
      middle_name: patronymic,
      birthdate: date_of_birth,
      email: email,
      emailKorp: emailCorp,
      phone: tel,
      course: course,
      eform: eform,
      elevel: elevel,
      nameprof: nameprof ? nameprof : "нет данных",
      namespec: namespec,
      department: department_name,
    },
  };

  function onSubmit() {
    setDisable(true);
    createReference({
      variables: {
        payload: {
          ...payload,
          fields: {
            ...payload.fields,
            AddInfo: additional,
          },
        },
      },
    })
      .then((res) => {
        dispatch(openSucces(true));
      })
      .then(() => dispatch(setIsCounting(true)))
      .catch((e) => {
        setDisable(false);
        console.log("onCreate error", e);
      });
  }

  function isDisabled() {
    if (disable) {
      return true;
    }
    if (isCounting) {
      return true;
    }
    if (payload.type === null) {
      return true;
    } else if (type !== null) {
      const isNulls = Object.values(payload.fields).find(
        (i: any) => i === null
      );
      return isNulls !== undefined;
    }

    return false;
  }

  const disabled = isDisabled();

  return (
    <>
      <div className="RefData">
        <h3 className="getRefSubtitle">Информация из вашего профиля</h3>
        <Row gutter={30}>
          <Col space={8} title="Имя" value={name} />
          <Col space={8} title="Фамилия" value={surname} />
          <Col space={8} title="Отчество" value={patronymic} />
        </Row>
        <Row gutter={30}>
          <Col space={8} title="Уровень подготовки" value={elevel} />
          <Col space={8} title="Форма обучения" value={eform} />
          <Col space={8} title="Направление подготовки" value={nameprof} />
        </Row>
        <Row gutter={30}>
          <Col space={8} title="Специальность" value={namespec} />
          <Col space={8} title="Факультет" value={department_name} />
          <Col space={8} title="Курс" value={course} />
        </Row>
        <Row gutter={30}>
          <Col space={8} title="Дата рождения" value={date_of_birth} />
          <Col space={8} title="Контактный телефон" value={tel} />
          <Col space={8} title="Личная электронная почта" value={email} />
        </Row>
        <Row>
          <Col
            space={8}
            title="Корпоративная электронная почта"
            value={emailCorp}
          />
        </Row>
        <h3 className="getRefSubtitle">Заполните информацию</h3>
        <div className="fieldsWrap">
          <ReferenceCategoryList type={type} />
          <AuthorityList authority={authority} />
        </div>
        <div className="referencesMainFields">
          <div className="column">
            <h3 className="getInfo">Как вы хотите забрать справку?</h3>
            {placeToGet?.map(
              (i: any) => (
                <div key={placeToGet.indexOf(i)} className="getChekbox">
                  <input
                    type="checkbox"
                    id="how-get"
                    onChange={() => handlePlaceClick(i)}
                    checked={isChecked(i)}
                  />
                  <label className="refDataLabel">{i}</label>
                </div>
              )
              /* <Checkbox key={placeToGet.indexOf(i)} onClick={() => handlePlaceClick(i)} label={i} checked={isChecked(i)} /> */
            )}
            <h3 className="getInfo">Сколько справок нужно</h3>
            <div className="amountWrap">
              <ChooseField items={refAmount} value={amount} />
            </div>
          </div>
          {type === "Справка-вызов" && (
            <div>
              <h3 className="getInfo">Дополнительные сведения</h3>
              <InputTextDeprecated
                defaultHint="Например, укажите организацию и ФИО работодателя"
                onChangeValue={setAdditional}
              />
            </div>
          )}
        </div>
        {/* <input className="refAmount" type="number" min="1" onChange={setAmount}></input> */}
        <div className="submitWrap">
          {isCounting && (
            <p>Вы сможете заказать еще одну справку через: {timeLeft} секунд</p>
          )}
          <button onClick={onSubmit} className="refSubmit" disabled={disabled}>
            Заказать справку
          </button>
        </div>
      </div>
      <SuccesPopup />
    </>
  );
}

export default RefData;
