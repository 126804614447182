/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { formatISO } from "date-fns";
import { useState } from "react";
import useChangeNotificationPublishStatus from "../../../../../gql/hooks/moderateNotifications/useChangeNotificationPublishStatus";
import useNotificationDraftPurgeMessages from "../../../../../gql/hooks/moderateNotifications/useNotificationDraftPurgeMessages";
import useNotificationDraftResetProcessed from "../../../../../gql/hooks/moderateNotifications/useNotificationDraftResetProcessed";
import { TitleH4 } from "../../../../common/GeneralElements/GeneralElements";
import Popup from "../../../../common/Popup/Popup";
import StatusBlock from "../../../../Portfolio/PortfolioTemplates/TemplateItem/StatusBlock/StatusBlock";
import OptionsButton from "../OptionsButton/OptionsButton";
import DeleteDraftPopupContent from "./DeleteDraftPopupContent/DeleteDraftPopupContent";
import EditDraftPopupContent from "./EditDraftPopupContent/EditDraftPopupContent";
import styles from "./NotificationItem.module.less";

const onEditHandler = ({ setIsPopupOpen }: any) => {
  setIsPopupOpen((prev: any) => !prev);
};

const NotificationItem = ({
  draft_id,
  type,
  title,
  is_active,
  html,
  is_important,
  publish_date,
  publish_time,
  target,
  student_filter,
  student_list,
  files,
  isAdmin,
  ...rest
}: any) => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const { onPublishDraftHandler } = useChangeNotificationPublishStatus();
  const { onResetDraftHandler } = useNotificationDraftResetProcessed();
  const { onRemoveSendedNotificationHandler } =
    useNotificationDraftPurgeMessages();

  return (
    <>
      <div className={styles.notificationItem}>
        <header className={styles.header}>
          <TitleH4>{title}</TitleH4>

          <div className={styles.statusWrapper}>
            <StatusBlock isActive={is_active} />

            <OptionsButton
              className={styles.optionsButton}
              onPublishDraftHandler={({ newStatus }: any) =>
                onPublishDraftHandler({
                  draftIds: [draft_id],
                  isActive: newStatus,
                })
              }
              onResetDraftHandler={() =>
                onResetDraftHandler({
                  draftIds: [draft_id],
                })
              }
              onRemoveSendedNotificationHandler={() =>
                onRemoveSendedNotificationHandler({
                  draftIds: [draft_id],
                })
              }
              onEditHandler={() =>
                onEditHandler({ setIsPopupOpen: setIsEditPopupOpen })
              }
              onDeleteHandler={() => setIsDeletePopupOpen(true)}
              isAdmin={isAdmin}
            />
          </div>
        </header>

        <div dangerouslySetInnerHTML={{ __html: html }} />

        <div className={styles.footer}>
          {is_important && <strong>Важное</strong>}
        </div>
      </div>

      {isEditPopupOpen && (
        <Popup
          isOpen={isEditPopupOpen}
          handlePopup={() => setIsEditPopupOpen((prev) => !prev)}
          isCloseButton
          withScroll
        >
          <EditDraftPopupContent
            onClosePopup={setIsEditPopupOpen}
            formData={{
              type,
              title,
              is_active,
              html,
              is_important,
              publish_dates: {
                from: formatISO(new Date(rest.from)),
                to: formatISO(new Date(rest.to)),
              },
              publish_time,
              target,
              student_filter,
              student_list,
              files,
            }}
            itemId={draft_id}
          />
        </Popup>
      )}

      {isDeletePopupOpen && (
        <Popup
          isOpen={isDeletePopupOpen}
          handlePopup={() => setIsDeletePopupOpen((prev) => !prev)}
          isCloseButton
          withScroll
        >
          <DeleteDraftPopupContent
            onClosePopup={setIsDeletePopupOpen}
            itemId={draft_id}
          />
        </Popup>
      )}
    </>
  );
};

export default NotificationItem;
