import { findContact } from "@/legacy/Utils/functions";
import { useME } from "@/shared/api";
import { UserRoles } from "@/shared/constants/userRoles";
import { useEffect } from "react";
import { useSelector } from "react-redux";
interface JivoWidgetProps {
  role: string;
}

export const JivoWidget: React.FC<JivoWidgetProps> = ({ role }) => {
  const personalInfo = useSelector((state: any) => state.auth.me);
  
  const phone = findContact(personalInfo?.data?.contacts, {
    kind_contact_information: "ТелефонМобильныйФизическиеЛица",
  });

  // console.log('personalInfo', personalInfo)
  // console.log('phone', phone)

  const injectedJavaScript = `
    function jivo_onLoadCallback() {
      jivo_api.setContactInfo({
        name: "${personalInfo.name || "Guest"}",
        email: "${personalInfo.email_work || personalInfo.email}",
        phone: "${phone !== '--' ? phone : '+79000000000'}",
        description: "User profile information"
      });
    }
  `;

  useEffect(() => {
    const scriptSrcMap: { [key: string]: string } = {
      [UserRoles.STUDENT]: "//code.jivo.ru/widget/ookXgaHqTu",
      [UserRoles.STUDENT_RAKUS]: "//code.jivo.ru/widget/ookXgaHqTu",
      [UserRoles.WORKER]: "//code.jivo.ru/widget/ZijtWOYqaF", //teacher
      [UserRoles.TEACHER]: "//code.jivo.ru/widget/ZijtWOYqaF", //teacher
      [UserRoles.TEACHER_GPH]: "//code.jivo.ru/widget/ZijtWOYqaF",
      [UserRoles.EMPLOYEE]: "//code.jivo.ru/widget/ZijtWOYqaF",
    };

    const scriptSrc = scriptSrcMap[role];

    if (scriptSrc) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      const scriptElement = document.createElement("script");
      scriptElement.text = injectedJavaScript;
      document.body.appendChild(scriptElement);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(scriptElement);
      };
    }
  }, [role, injectedJavaScript]);

  return null;
};
