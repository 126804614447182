import { UserRoles, } from '@/shared/constants/userRoles'


interface Role {
  name: UserRoles,
  title: string,
}

export const roles: Role[] = [
  {
    name: UserRoles.TESTER,
    title: 'Тестировщик',
  },
  {
    name: UserRoles.STUDENT,
    title: 'Студент',
  },
  {
    name: UserRoles.STUDENT_RAKUS,
    title: 'Cтудент',
  },
  {
    name: UserRoles.EMPLOYEE,
    title: 'Сотрудник',
  },
  {
    name: UserRoles.TEACHER,
    title: 'Преподаватель',
  },
  {
    name: UserRoles.TEACHER_GPH,
    title: 'Преподаватель (ГПХ)',
  },
  {
    name: UserRoles.WORKER,
    title: 'Сотрудник',
  },
  {
    name: UserRoles.DIRECTOR_INSTITUTE,
    title: 'Директор института',
  },
  {
    name: UserRoles.DIRECTOR_FACULTY,
    title: 'Декан факультета',
  },
  {
    name: UserRoles.PA_MODER,
    title: 'Модератор ПД',
  },
  {
    name: UserRoles.OSRV_MODER,
    title: 'Модератор ОС (ОСРВ)',
  },
  {
    name: UserRoles.URTOS_MODER,
    title: 'Администратора портфолио',
  },
  {
    name: UserRoles.PORTFOLIO_MFC_MODER,
    title: 'Модератор портфолио (МФЦ)',
  },
  {
    name: UserRoles.PORTFOLIO_KURATOR,
    title: 'Куратор портфолио',
  },
  {
    name: UserRoles.URVO_ADMIN,
    title: 'Администратор ЭК',
  },
  {
    name: UserRoles.AUP_ADMIN,
    title: 'Администратор ЭК-АУП',
  },
  {
    name: UserRoles.DMS_MODER,
    title: 'Модератор ЭК (ДМС)',
  },
  {
    name: UserRoles.UFPN_MODER,
    title: 'Модератор ЭК (УФПН)',
  },
  {
    name: UserRoles.UONID_MODER,
    title: 'Модератор ЭК (УОНИД)',
  },
  {
    name: UserRoles.DM_MODER,
    title: 'Модератор ЭК (ДМ)',
  },
  {
    name: UserRoles.UKP_MODER,
    title: 'Модератор ЭК (УКП)',
  },
  {
    name: UserRoles.TPD_MODER,
    title: 'Модератор ЭК (ТПД)',
  },
  {
    name: UserRoles.URVO_MODER,
    title: 'Модератор ЭК (УРВО)',
  },
  {
    name: UserRoles.URVO_TWO_MODER,
    title: 'Модератор ЭК (УРВО) 2',
  },
  {
    name: UserRoles.AUP_PFU_MODER,
    title: 'Модератор ЭК-АУП (ПФУ)',
  },
  {
    name: UserRoles.AUP_PK_MODER,
    title: 'Модератор ЭК-АУП (ПК)',
  },
  {
    name: UserRoles.AUP_DMS_MODER,
    title: 'Модератор ЭК-АУП (ДМС)',
  },
  {
    name: UserRoles.PROSMOTR_MOE_OBUCHENIE,
    title: 'Посмотреть МО',
  },
  {
    name: UserRoles.AUP_CK_MODER,
    title: 'Модератор ЭК-АУП (СК)',
  },
  {
    name: UserRoles.NOTIFICATION_MODER,
    title: 'Модератор уведомлений',
  },
  {
    name: UserRoles.NOTIFICATION_ADMIN,
    title: 'Администратор уведомлений',
  },
  {
    name: UserRoles.SELFSIGNUP,
    title: 'Гость',
  },
  {
    name: UserRoles.UNKNOWN,
    title: 'Неизвестная роль',
  },
]
