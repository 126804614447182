import { gql, } from '@/__generated__'
import { useQuery, } from '@apollo/client'
import { useState, useEffect, } from 'react'

type PresignedDownloadUrl = {
  downloadUrl: string
}

type FileNotFoundError = {
  message: string
}

type InvalidArgumentError = {
  message: string
}

type DownloadResult = PresignedDownloadUrl | FileNotFoundError | InvalidArgumentError

const GET_DOWNLOAD_URL = gql(`
    query GetDownloadUrl($fileId: String!) {
        getPresignedDownloadUrl(fileId: $fileId) {
            __typename
            ... on FileNotFoundError {
                message
            }
            ... on InvalidArgumentError {
                message
            }
            ... on PresignedDownloadUrl {
                downloadUrl
            }
        }
    }
`)

const useDownloadS3Test = (fileId: string) => {
  const { data, error, loading, } = useQuery(GET_DOWNLOAD_URL, {
    variables: { fileId, },
  })

  const [
    downloadUrl,
    setDownloadUrl,
  ] = useState<string | null>(null)
  const [
    errorMessage,
    setErrorMessage,
  ] = useState<string | null>(null)

  useEffect(() => {
    if (data && data.getPresignedDownloadUrl !== null && data.getPresignedDownloadUrl !== undefined) {
      if ('downloadUrl' in data.getPresignedDownloadUrl) {
        setDownloadUrl(data.getPresignedDownloadUrl.downloadUrl as string)
      } else if ('message' in data.getPresignedDownloadUrl) {
        // @ts-ignore
        setErrorMessage(data.getPresignedDownloadUrl.message || 'Unknown error occurred')
      } else {
        setErrorMessage('Unknown error occurred')
      }
    }
  }, [
    data,
  ])

  return {
    downloadUrl,
    errorMessage,
    loading,
  }
}

export default useDownloadS3Test
