import styles from './AddStudents.module.less'
import { useState, useCallback, } from 'react'
import { DebounceInput, } from 'react-debounce-input'
import FilterDropDown from '../../../../common/FilterDropDown/FilterDropDown'
import Persons from '../../../../common/PersonsInput/PersonsInput'
import InstitutesField from '../../../../MyEducation/HighEducation/LearningPathway/AddDisciplinePopup/InstitutesField'
import { useDispatch, useSelector, } from 'react-redux'
import {
  addCriteriaToStudentFilter,
  addStudentToList,
  CREATE_NOTIFICATION_DRAFT_FORM_NAMES,
  removeCriteriaFromStudentFilter,
  removeStudentFromList,
} from '../../../../../redux/moderateNotificationsSlice/moderateNotificationsSlice'
import UniversalStudentDataSearch from '../../../../common/UniversalStudentDataSearch/UniversalStudentDataSearch'
import ChipsBox from '@/legacy/components/common/ChipsBox/ChipsBox'
import InputCheckbox from '@/legacy/components/common/InputCheckbox/InputCheckbox'


const AddStudents = () => {
  const dispatch = useDispatch()
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentFilter = useSelector(state => state.moderateNotifications.createNotificationForm?.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentFilter).value)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const studentList = useSelector(state => state.moderateNotifications.createNotificationForm?.find((field: any) => field.name === CREATE_NOTIFICATION_DRAFT_FORM_NAMES.studentList).value)

  const memoAddCriteriaToStudentFilter = useCallback((field: any, newValue: any) => dispatch(
    addCriteriaToStudentFilter({
      field,
      newValue,
    })
  ), [
    dispatch,
  ])
  const memoRemoveCriteriaFromStudentFilter = useCallback((field: any, newValue: any) => dispatch(removeCriteriaFromStudentFilter({
    field,
    newValue,
  })), [
    dispatch,
  ])
  const memoAddStudentToList = useCallback((value: any) => dispatch(addStudentToList(value)), [
    dispatch,
  ])
  const memoRemoveStudentFromList = useCallback((value: any) => dispatch(removeStudentFromList(value)), [
    dispatch,
  ])


  const [
    performerValue,
    setPerformerValue,
  ] = useState<any>()
  const [
    currentField,
    setCurrentField,
  ] = useState(null)


  const handleInstitutes = (selectItem: any, all: any) => {
    if (selectItem === 'Выбрать все') {
      all
        .filter((field: any) => field !== 'Выбрать все')
        .forEach((institute: any) => memoAddCriteriaToStudentFilter('department', institute))
    } else if (!studentFilter.department.find((item: any) => selectItem === item)) {
      memoAddCriteriaToStudentFilter('department', selectItem)
    }
  }

  const isBasisChecked = studentFilter.basis.includes('Полное возмещение затрат')

  return (
    <form className={ styles.form }>
      <div>
        <InputCheckbox
          label="Полное возмещение затрат"
          checked={isBasisChecked}
          onChange={(e: any) => {
            const isChecked = e.target.checked
            if (isChecked) {
              memoAddCriteriaToStudentFilter('basis', 'Полное возмещение затрат')
            } else {
              memoRemoveCriteriaFromStudentFilter('basis', 'Полное возмещение затрат')
            }
          }}
        />
      </div>

      <div className={ styles.inputWrapper }>
        { studentFilter?.course?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.course }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'course') }
          />
        ) }

        <FilterDropDown
          items={ [
            1,
            2,
            3,
            4,
            5,
            6,
          ] }
          title="Курс"
          defaultHint="Выберите курс"
          onClickHandler={ (newValue: any) => memoAddCriteriaToStudentFilter('course', newValue) }
          withBorder="true"
        />
      </div>


      {/* Скрыто от глаз людских, ибо есть в этом фильтре номер курса */ }
      {/* <div className={ styles.inputWrapper }>
        { studentFilter?.semester?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.semester }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'semester') }
          />
        ) }

        <FilterDropDown
          items={ [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] }
          title="Семестр"
          defaultHint="Выберите семестр"
          onClickHandler={ newValue => memoAddCriteriaToStudentFilter('semester', newValue) }
          withBorder="true"
        />
      </div> */}


      <div className={ styles.inputWrapper }>
        { studentFilter?.grade?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.grade }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'grade') }
          />
        ) }

        <FilterDropDown
          items={ [
            'Бакалавр',
            'Специалист',
            'Магистр',
          ] }
          title="Степень"
          defaultHint="Выберите уровень обучения"
          onClickHandler={ (newValue: any) => memoAddCriteriaToStudentFilter('grade', newValue) }
          withBorder="true"
        />
      </div>


      <div className={ styles.inputWrapper }>
        { studentFilter?.eform?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.eform }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'eform') }
          />
        ) }

        <FilterDropDown
          items={ [
            'Очная',
            'Очно-заочная',
            'Заочная',
          ] }
          defaultHint="Выберите форму обучения"
          onClickHandler={ (newValue: any) => memoAddCriteriaToStudentFilter('eform', newValue) }
          withBorder="true"
        />
      </div>


      <div className={ styles.inputWrapper }>
        { studentFilter?.department?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.department }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'department') }
          />
        ) }

        <InstitutesField setInstitute={ handleInstitutes } />
      </div>


      <div className={ styles.inputWrapper }>
        { studentFilter?.namespec?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.namespec }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'namespec') }
          />
        ) }

        <UniversalStudentDataSearch
          type={ 'namespec' }
          callback={ (newValue: any) => memoAddCriteriaToStudentFilter('namespec', newValue) }
        />
      </div>


      <div className={ styles.inputWrapper }>
        { studentFilter?.nameprof?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.nameprof }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'nameprof') }
          />
        ) }

        <UniversalStudentDataSearch
          type={ 'nameprof' }
          callback={ (newValue: any) => memoAddCriteriaToStudentFilter('nameprof', newValue) }
        />
      </div>


      <div className={ styles.inputWrapper }>
        { studentFilter?.group?.length > 0 && (
          <ChipsBox
            items={ studentFilter?.group }
            onRemoveItem={ memoRemoveCriteriaFromStudentFilter.bind(null, 'group') }
          />
        ) }

        <UniversalStudentDataSearch
          type={ 'group' }
          callback={ (newValue: any) => memoAddCriteriaToStudentFilter('group', newValue) }
        />
      </div>


      <div className={ styles.inputWrapper }>
        { studentList.length > 0 && (
          <ChipsBox
            items={ studentList.map((student: any) => student.full_name) }
            onRemoveItem={ (answer: any) => {
              const studentInfo = studentList.find((student: any) => student.full_name === answer)
              memoRemoveStudentFromList(studentInfo)
            } }
          />
        ) }

        <div className="dateWrap multiple" style={ {
          width: '100%',
          margin: 0,
        } }>
          <DebounceInput
            required
            onChange={ e => setPerformerValue(e.target.value) }
            debounceTimeout={ 300 }
            type="text"
            placeholder='студенты'
            // @ts-expect-error TS(2345): Argument of type '"исполнитель"' is not assignable... Remove this comment to see the full error message
            onClick={ () => setCurrentField('исполнитель') }
          />

          { performerValue?.length > 3 && (
            <Persons
              mode="person"
              field="исполнитель"
              handler={ memoAddStudentToList }
              // @ts-expect-error TS(2345): Argument of type '"исполнитель"' is not assignable... Remove this comment to see the full error message
              setCurrentField={ () => setCurrentField('исполнитель') }
              currentField={ currentField }
              value={ performerValue }
              clear={ setPerformerValue }
            />
          ) }
        </div>
      </div>
    </form>
  )
}


export default AddStudents
