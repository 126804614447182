/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "./Profile.less";
import HistoryLinks from "@/shared/ui/HistoryLinks/HistoryLinks";
import PersonalForm from "./PersonalForm/PersonalForm";
import StudyForm from "./StudyForm/StudyForm";
import ContactsForm from "./ContactsForm/ContactsForm";
import useFormWithValidation from "../../hooks/useFormWithValidation";
import ProfileBlock from "./ProfileBlock/ProfileBlock";
import ProfileForm from "./ProfileBlock/ProfileForm";
import Avatars from "./Avatars/Avatars";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";

function Profile() {
  const { /* values, */ handleChange, errors, isValid } =
    useFormWithValidation();
  const isButtonActive = !(isValid === false || errors.confirmPassword);

  const role = useGetRole();

  return (
    <div className="profile">
      <HistoryLinks
        links={[
          {
            value: "Главная",
            path: "/",
          },
        ]}
        loc="Мой профиль"
      />
      <h2 className="profileTitle">Мой профиль</h2>
      <div className="profileContent">
        <div className="personalWrap">
          <Avatars />
          <h3 className="profileSubtitle">Личное</h3>
          <PersonalForm />
        </div>
        <ProfileForm component={ContactsForm} title="Контакты" />
        {(role === "student" || role === "student-rakus") && (
          <ProfileBlock component={StudyForm} title="Учеба" />
        )}{" "}
        {/* либо кадровая инфа для работника */}
        {/*  <form className="personalWrap">
          <h3 className="profileSubtitle">Изменить пароль</h3>
          <PasswordForm handleChange={handleChange} errors={errors} />
          <Button disabled={!isButtonActive} style={{ background: "#7671DD", alignSelf: "end", width: "fit-content", color: "white", margin: "30px 30px 0 0" }} value="Изменить пароль" />
        </form> */}
      </div>

      {/*
      * Эта кнопка скрыта в соответствии с замечанием ТГУ
      <Button value="Сохранить изменения" style={{ background: "#7671DD", alignSelf: "end", width: "fit-content", color: "white", margin: `69px 0 0 0` }} />
       */}
    </div>
  );
}

export default Profile;
