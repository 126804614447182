/* eslint-disable react-hooks/exhaustive-deps */
// @ts-expect-error TS(2616): 'React' can only be imported by using 'import Reac... Remove this comment to see the full error message
import { React, useMemo, useState } from "react";
import "./ProfilePopup.less";
// import clsx from 'clsx';
// import { popupClick } from '../../../redux/HeaderSlices/PopupSlice';
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
// import Check from '@mui/icons-material/Check';
// import Fade from '@mui/material/Fade';
import Menu from "@mui/material/Menu";
import { IconCheck } from "@tabler/icons";
import { useSelector } from "react-redux";
import { chooseRole } from "@/legacy/redux/features/auth/auth";
import ChangeUserIdPopup from "../ChangeUserIdPopup/ChangeUserIdPopup";
import { roles } from "./rolesCatalog";
import { setRealUserGuid } from "@/legacy/redux/FakeChageUserSlice/FakeChageUserSlice";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";

function ProfilePopup({
  items,
  redirect,
  signOut,
  open,
  anchorEl,
  closePopup,
}: any) {
  const dispatch = useDispatch();
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const currentUser = useSelector((state) => state.auth.me);
  const isFakeUser = !!useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    (state) => state.fakeChangeUser.realUserGuid
  );

  const [isChangeUser, setIsChangeUser] = useState(false);

  const role = useGetRole();

  const profileItems = useMemo(() => {
    const handleClick = (i: any) => {
      redirect(i.path, i.value);
      closePopup();
    };
    return items.map((i: any, index: any) => {
      return (
        <MenuItem key={index} onClick={() => handleClick(i)}>
          {i.value}
        </MenuItem>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const getRoleName = (roleName: any) => {
    const role = roles?.find((role) => role?.name === roleName);

    if (role) {
      if (role?.name === "student-rakus") {
        role.title = "Студент (РАКУС)";
      }
      return role?.title;
    }
    return "Неизвестная роль";
  };

  const setNewRole = (i: any) => {
    if (i === "teacher") {
      dispatch(chooseRole("worker"));
    } else {
      dispatch(chooseRole(i));
    }
    closePopup();
  };

  const isCurrent = () => {
    if (role === "worker") {
      return "teacher";
    }
    return role;
  };

  const availableRoles = useMemo(() => {
    if (currentUser?.groups) {
      const rolesList = currentUser?.groups.map((group: any) => {
        if (group === "internal") {
          return null;
        }
        return (
          <MenuItem
            key={group}
            onClick={() => {
              if (roles.some((role) => role.name === group)) {
                setNewRole(group);
              }
            }}
          >
            {isCurrent() === group && (
              <IconCheck style={{ marginRight: "30px" }} />
            )}
            {getRoleName(group)}
          </MenuItem>
        );
      });

      // if (!isFakeUser && rolesList.find((role: any) => role && role.key === 'student-rakus')) {
      //   return rolesList.filter((role: any) => role && role.key !== 'student')
      // }
      return rolesList;
    }
  }, [currentUser, role]);

  const realUserGuid = useSelector(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    (state) => state?.fakeChangeUser?.realUserGuid
  );
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const userGuid = useSelector((state) => state?.auth?.me?.data?.guid);

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchorEl}
        id="basic-menu"
        onClose={closePopup} /* TransitionComponent={Fade} */
      >
        {availableRoles}
        <Divider />
        {profileItems}

        {role === "tester" && !realUserGuid && (
          <MenuItem
            onClick={() => {
              setIsChangeUser(true);
              closePopup();
            }}
          >
            Сменить пользователя
          </MenuItem>
        )}
        {realUserGuid && realUserGuid !== userGuid && (
          <MenuItem
            onClick={() => {
              dispatch(setRealUserGuid(null));
              window.location.assign(`api/spy/set?redirect=/`);
            }}
          >
            Завершить режим тестирования
          </MenuItem>
        )}

        <MenuItem onClick={signOut}>Выход</MenuItem>
      </Menu>

      <ChangeUserIdPopup
        isOpen={isChangeUser}
        handlePopup={() => setIsChangeUser(!isChangeUser)}
      />
    </>
  );
}

export default ProfilePopup;
