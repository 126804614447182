export function handleAcceptAllResponses(responses: any, paControl: any, openToast: any, addNotification: any, project: any, req_id: any) {
  const newResponses = responses.map((i: any) => {
    // Only update the status to 'approved' if it's not already 'rejected'
    if (i.approved !== 'rejected') {
      return {
        ...i,
        approved: 'approved',
      };
    }
    return i; 
  });

  const params = {
    event: 'change',
    payload: {
      req_id: req_id,
      args: {
        status: 'ready',
        data: {
          ...project.data,
          responses: newResponses,
        },
      },
      action: 'update',
    },
  };

  paControl({ variables: { params } })
    .then(() => {
      newResponses.forEach((resp: any) => {
        if (resp.approved === 'approved') {
          addNotification?.('Проектная деятельность', [resp.id], {
            message: 'Ваш отклик на участие в проекте одобрен',
            reqId: req_id,
          }, {
            path: '/my-projects',
            from: 'Проектная деятельность',
          });
        }
      });
    })
    .then(() => {
      openToast?.({ message: 'Все отклики одобрены' });
    })
    .catch((e: any) => {
      console.log('onCreate error', e);
    });
}
