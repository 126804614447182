import React, { Suspense, useEffect, useState, } from 'react'
import './App.less'
// eslint-disable-next-line import/extensions
import '@/widgets/SideBar/ui/SideBar/SideBar.less'

import AppRouter from './AppRouter/AppRouter'
import { Helmet, } from 'react-helmet-async'
// @ts-expect-error TS(2307): Cannot find module '../../assets/images/favicon.ic... Remove this comment to see the full error message
// eslint-disable-next-line import/extensions
import logo from '@/shared/assets/images/favicon.ico'
import { ApolloClient, } from '@/app/providers/ApolloClientProvider'

import { PersistGate, } from 'redux-persist/lib/integration/react'
import { Provider, } from 'react-redux'
import store, { persistor, } from '@/legacy/redux/store'
import AppProvider from './providers/AppProvider'
import { Preloader, } from '@/shared'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2'
import SmallDevicesPage from '@/legacy/components/common/SmallDevicesPage/SmallDevicesPage'
import LocationListener from './LocationListener/LocationListener'
import { isAndroid, isIOS, } from 'react-device-detect'
import useGetRole from '@/legacy/redux/hooks/common/useGetRole'
import { EcSubscriptionsProvider, } from '@/features'
import { Header, Footer, SideBarTgu, } from '@/widgets'
import Login from '@/features/Login/Login'
import Logout from '@/features/Logout/Logout'

import Snowfall from "react-snowfall";
import { useSelector, } from 'react-redux'
import { useME } from '@/shared/api'

const MainScreen = () => {
  const me:any = useME()
  const role = useGetRole()
  const location = useLocation()
  const isSnow = useSelector((state: any) => state.util.snowAnymation);


  // const [snowflakeImages, setSnowflakeImages] = useState<any>([])
  // useEffect(() => {
  //   // Array of image URLs
  //   const imageUrls = [
  //     'https://www.freeiconspng.com/thumbs/heart-png/heart-png-15.png',
  //   ];

  //   // Function to load images
  //   const loadImages = (urls:any) => {
  //     const promises = urls.map((url:any) => {
  //       return new Promise((resolve, reject) => {
  //         const img = new Image();
  //         img.src = url;
  //         img.onload = () => resolve(img);
  //         img.onerror = reject;
  //       });
  //     });

  //     Promise.all(promises)
  //       .then(setSnowflakeImages)
  //       .catch((error) => console.error('Error loading images:', error));
  //   };

  //   loadImages(imageUrls);
  // }, []);
  // const today = new Date();
  // const isValentinesDay = today.getMonth() === 1 && today.getDate() === 14;

  
  useEffect(() => {
    const page = document.querySelector('.main-scroll-bar')?.firstChild
    if (page) {
      // @ts-expect-error TS(2339): Property 'scrollTo' does not exist on type 'ChildN... Remove this comment to see the full error message
      page.scrollTo(0, 0)
    }
  }, [
    location,
  ])

  return (
    <>
      <Helmet>
        <title>ЛК ТГУ</title>
        <link rel="icon" href={ logo } />
      </Helmet>

      { role !== null ? (
        <div className="page">
          <SideBarTgu />
          {/* comment to turn off  */}
          {/* {isSnow && <Snowfall color="#AFEEEE" style={ {zIndex: "1000000000000"}}  snowflakeCount={400}/>} */}
          {/* {isValentinesDay && <Snowfall color="#fc037b" images={snowflakeImages} style={ {zIndex: "1000000000000"}}  snowflakeCount={400}/>} */}
          <main className="main" id="scrollableDiv">
            <Scrollbars className="main-scroll-bar">
              <Header />
              <AppRouter /* isLogged={isLogged} */ />
              <Footer />
              {/*   { !isLogged ? <div>.....крутится прелоадер....</div> : <></> } */ }
            </Scrollbars>
          </main>
        </div>
      ) : (
        <Preloader />
      ) }
    </>
  )
}

const Root = () => {
  return (
    <ApolloClient>
      <AppProvider>
        <EcSubscriptionsProvider>
          <MainScreen />
          <LocationListener />
        </EcSubscriptionsProvider>
      </AppProvider>
    </ApolloClient>
  )
}

const AppRoutes = () => {
  return (
    <Suspense fallback="">
      <Routes>
        <Route path="/*" element={ <Root /> } />
        <Route path="/login" element={ <Login /> } />
        <Route path="/logout" element={ <Logout /> } />
      </Routes>
    </Suspense>
  )
}

function App () {
  // const stud = useSelector((state) => state.prof.studentInfo)
  useEffect(() => {
    if (isAndroid) {
      window.location.replace('mytgumobilev1://app')
    } else if (isIOS) {
      window.location.replace('mytgumobilev1://')
    }
  }, [])

  return !isIOS && !isAndroid ? (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <Router>
          <AppRoutes />
        </Router>
      </PersistGate>
    </Provider>
  ) : (
    <SmallDevicesPage />
  )
}

export default App
