export const MAIN_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const LOGOUT = "/logout";
export const SCHEDULE_ROUTE = "/schedule";
export const SCHEDULE_TEACHER_ROUTE = "/schedule-teacher";
export const RECORD_BOOK_ROUTE = "/record-book";
export const MY_EDUCATION_ROUTE = "/my-education";
export const PROJECTS_INTRODACTION = "/project-activity-introduction";
export const EDIT_TRACK_PAGE = "/edit-track";
export const LEARNING_PATHWAY_ROUTE = "/learning-pathway";
export const EDIT_DISCIPLINE_ROUTE = "/learning-pathway/detail/edit-discipline";
export const DETAIL_PAGE_ROUTE = "/learning-pathway/detail";
export const LIBRARY_ROUTE = "/library";
export const LIBRARY_BOOK_DETAILS = "/library-book-details";
export const OPVO_ROUTE = "/opvo";
export const COURSEWORK_ROUTE = "/coursework";
export const PROJECTS_ROUTE = "/projects";
export const STUDY_JOURNAL_ROUTE = "/study-journal";
export const NEWS_ROUTE = "/news";
export const PROFILE_ROUTE = "/profile";
export const ADS_ROUTE = "/ads";
export const MyReferences_Route = "/my-references";
export const MFC_MY_APPLICATIONS_ROUTE = "/mfc-applications";
export const MFC_APL_CONSTRACTOR_ROUTE = "/mfc-get-application";
export const MFC_LOGS = "/mfc-logs";
export const GET_REFERENCE_ROUTE = "/get-reference";
export const JOURNAL_INNER = "/journal-inner";
export const NOT_FOUND_ROUTE = "not-found";
export const PAYMENT = "/payment";
export const SCOLARSHIP = "/scholarship";

export const EFFECTIVE_CONTRACT_ROUTE = "/effective-contract";
export const EFFECTIVE_CONTRACT_AMS_ROUTE = "/effective-contract-ams";
export const EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE =
  "/effective-contract-ams/moderate-achievements";
export const EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE =
  "/effective-contract-ams/moderate-achievement-by";
export const EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE =
  "/effective-contract/moderate-achievement";
export const EFFECTIVE_CONTRACT_APPEALS_ROUTE = "/effective-contract/appeals";
export const EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE =
  "/effective-contract-ams/appeals";
export const EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE =
  "/effective-contract/add-achievement";
export const EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE =
  "/effective-contract/moderate-achievement-by";
export const EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE =
  "/effective-contract/moderate-appeal-by";
export const EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE =
  "/effective-contract-ams/moderate-appeal-by";
export const EFFECTIVE_CONTRACT_STEPS_ROUTE =
  "/moderate-effective-contract-stages";
export const EFFECTIVE_CONTRACT_TEACHER_RATING_ROUTE =
  "/effective-contract/teacher-rating";
export const EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE =
  "/effective-contract/moderate-threshold-values";

export const PROJECTS_ALL = "/projects-all";
export const PROJECT_SEMINAR = "/project-seminar";
export const PROJECT_SEMINAR_BY = "/project-seminar/by";
export const MY_PROJECTS = "/my-projects";
export const PROJECT = "/project";
export const MY_APPLICATIONS = "/my-applications";
export const PROJECT_APPLY = "/apply-project";
export const MODERATE_APPLICATIONS = "/moderate-applications";
export const TASK = "/task";
export const NOTIFICATIONS = "/notifications";
export const MODERATE_NOTIFICATIONS = "/moderate-notifications";

export const PORTFOLIO = "/portfolio";
export const MODERATE_PORTFOLIO = "/moder-portfolio";
export const PORTFOLIO_RATING = "/portfolio-rating";
export const PORTFOLIO_TEMPLATES = "/portfolio-templates";
export const PORTFOLIO_INDICATORS = "/portfolio-indicators";
export const PORTFOLIO_EDIT_INDICATOR = "/portfolio-edit-indicator";

export const RATING_BY_ID = "/personal-rating";
export const MODERATE_ACHIVMENTS = "/moderate-achivments";
export const ANOUNCES = "/anounces";
export const ANOUNCE_INNER = "/announce-inner";
export const HIGH_EDUCATION = "/high-education";
export const DPO_EDUCATION = "/dpo-education";
export const PRACTICE = "/practice";
export const FACULTATIVE = "/facultative";

export const MY_DUTY_NOTES = "/duty-notes";
export const GET_DUTY_NOTE = "/get-duty-note";
export const SPECIALTIES_LIST = "/specialties-list";
export const MAJOR_ROUTE = "/majors";
export const EDIT_MAJOR = "/edit-major";
export const MAJOR_DETAILS = "/major-details";
export const STUDENT_MAJORS = "student-majors";
export const TEST_ROUTE = "/test";
