/* eslint-disable no-console */
import "./HighEducation.less";
import NavigationCard from "../../common/NavigationCard/NavigationCard";
import HistoryLinks from "@/shared/ui/HistoryLinks/HistoryLinks";
import second from "@/shared/assets/images/navigation/noun_contact_4290938 1.svg";
import { IconBook, IconFilePlus, IconSchool, IconTools } from "@tabler/icons";
import { useSelector } from "react-redux";
import useGetRole from "@/legacy/redux/hooks/common/useGetRole";

const HighEducation = () => {
  const role = useGetRole();

  const navigationCards = [
    {
      name: "Обязательные дисциплины",
      desc: "Перейти в раздел",
      path: "/my-education",
      img: second,
      role: ["student", "student-rakus"],
      icon: <IconBook size={80} color="#7671DD" strokeWidth="1" />,
    },
    {
      name: "Выбор дисциплин и факультативов",
      desc: "Перейти в раздел",
      path: "/learning-pathway",
      img: second,
      role: [
        "student",
        "osrv-moder",
        "student-rakus",
        "prosmotr-moe-obuchenie",
      ],
      icon: <IconFilePlus size={80} color="#FC8D3B" strokeWidth="1" />,
    },
    {
      name: "Мейждоры",
      desc: "Перейти в раздел",
      path: "/student-majors",
      img: second,
      // role: ["student", "student-rakus"],
      role: ["tester"],
      icon: <IconSchool size={80} color="#7671DD" strokeWidth="1" />,
    },
    /* { name: "Практика", desc: "Перейти в раздел", path: '/practice', img: second, role: ['student', "osrv-moder"], icon: <IconTools size={80} color="#EB5757" strokeWidth="1"/>  },
  { name: "Курсовая работа", desc: "Перейти в раздел", path: '/coursework', img: second, role: ['student', "osrv-moder"], icon: <IconFileAnalytics size={80} color="#00B7DF" strokeWidth="1"/>  },
  { name: "ВКР", desc: "Перейти в раздел", path: '/coursework', img: second, role: ['student', "osrv-moder"], icon: <IconSchool size={80} color="#FBB714" strokeWidth="1"/>  } */
  ];

  return (
    <div className="InnerPage highEducation">
      <HistoryLinks
        links={[
          {
            value: "Главная",
            path: "/",
          },
        ]}
        loc="Высшее образование"
      />

      <div className="studentInner scroll">
        <h2 className="profileTitle">Высшее образование</h2>
        <div className="Navigation">
          {navigationCards.map((i) =>
            i.role.includes(role) ? (
              <NavigationCard props={i} key={i.name} />
            ) : null
          )}
          {role === "osrv-moder" /* ||
            (studInfo[0]?.course === 1 &&
              studInfo[0]?.eform === "Очная" &&
              format(new Date(Date.now()), "M") !== "10") */ && (
            <NavigationCard
              props={{
                name: "Введение в проектную деятельность",
                desc: "Перейти в раздел",
                path: "/project-activity-introduction",
                img: second,
                role: [
                  /*  'student',
               'student-rakus', */
                  "osrv-moder",
                ],
                icon: <IconSchool size={80} color="#00B7DF" strokeWidth="1" />,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HighEducation;
