import React, { Fragment, useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import { formatDistanceToNowStrict } from 'date-fns'
import { ru } from 'date-fns/locale'
import Image from '@/legacy/components/common/Image/Image'
import AddStudentResultInput from '@/legacy/components/Projects/ProjectPage/InfoProject/components/AddStudentResultInput/AddStudentResultInput'
import useUpdateProject from '@/legacy/gql/hooks/projects/useUpdateProject'
import { CreatePayloadParamDataResponses, OnUpdateProjectPayload } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { getSemester } from '@/legacy/Utils/functions'
import { renderGraduatingSemesterButtons, renderNonGraduatingSemesterButtons } from '@/legacy/components/Projects/ProjectPage/InfoProject/components/ResultsBlockForSupevisor/lib/RenderButtons'

interface ResultsBlockForSupervisorProps {
  responsesList: CreatePayloadParamDataResponses[]
  projectId: number
  projectPayloadData: OnUpdateProjectPayload
}

const ResultsBlockForSupervisor = ({
  responsesList,
  projectId,
  projectPayloadData,
}: ResultsBlockForSupervisorProps) => {
  const { onUpdateProject } = useUpdateProject()
  const [formValues, setFormValues] = useState<{ [key: number]: string }>({})

  const handleInputChange = (index: number, value: string) => {
    setFormValues((prevValues) => ({ ...prevValues, [index]: value }))
  }

  const onUpdateStudentWorkStatus = (userId: string, newStatus: string) => {
    const newProjectPayloadData = JSON.parse(JSON.stringify(projectPayloadData));
    const student = newProjectPayloadData.args.data.responses.find(
      (student: CreatePayloadParamDataResponses) => student.id === userId
    );
    if (student) {
      student.studentResults = {
        ...student.studentResults,
        status: newStatus,
      };
      onUpdateProject(newProjectPayloadData)
    }
  };

  return (
    <div className="projResults">
      <h2>Результаты работы студентов</h2>

      {responsesList
        .filter((response) => response.approved === 'approved')
        .map((student, index) => {
          const { id, name, birth, spec_info, studentResults } = student
          const studentProfile = spec_info[0]
          const isGraduatingSemester = [4, 7].includes(getSemester(studentProfile.course))

          const formattedAge = formatDistanceToNowStrict(
            new Date(birth.split('.').reverse().join(',')),
            { locale: ru }
          )

          useEffect(() => {
            console.log('Current studentResults status:', studentResults?.status);
          }, [studentResults]);

          return (
            <Fragment key={id}>
            <br />
            <div className="applyResponse">
              <div className="imageWrap">
                <Image id={id} />
                <div className="responceInfo">
                  <h2>{name}</h2>
                  <p className="age">{`${formattedAge} · Гражданство: Россия`}</p>
                  <p className="spec">Профиль: <span>{studentProfile.nameprof}</span></p>
                  <p className="spec">Направление подготовки: <span>{studentProfile.namespec}</span></p>
                  <p className="spec">Курс: <span>{studentProfile.course}</span></p>
                </div>
              </div>
          
              <div className="applyResponse__buttons-wrapper">
                {isGraduatingSemester ? (
                  renderGraduatingSemesterButtons(studentResults, id, formValues, index, handleInputChange, onUpdateStudentWorkStatus)
                ) : (
                  renderNonGraduatingSemesterButtons(studentResults, id, onUpdateStudentWorkStatus)
                )}
              </div>
            </div>
          
            <AddStudentResultInput
              studentResults={studentResults}
              projectId={projectId}
              responderGuid={id}
              projectPayloadData={projectPayloadData}
            />
          
            <Divider />
            </Fragment>
          )
        })}
    </div>
  )
}

export default ResultsBlockForSupervisor;
