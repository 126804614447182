import { CreatePayloadParam, OnUpdateProjectPayload, } from '@/legacy/gql/hooks/projects/useUpdateProjectTypes'
import { gql, useMutation, } from '@apollo/client'


const PA_CONTROL = gql`
  mutation PaControl($params: JSON) {
    paControl(params: $params)
  }
`


const useUpdateProject = () => {
  const [
    onUpdate,
  ] = useMutation(PA_CONTROL)


  const onUpdateProject = (payload: OnUpdateProjectPayload) => {
    onUpdate({
      variables: {
        params: {
          event: 'change',
          payload: {
            ...payload,
            action: 'update',
          },
        },
      },
    })
  }


  return { onUpdateProject, }
}


/**
 * Вспомогательная функция возвращающая payload для передачи в мутацию paControl.
 * На вход надо дать объект с Проектом.
 */
export const onAddStudentResultCreatePayload = (project: CreatePayloadParam): OnUpdateProjectPayload => {
  const payload = {
    req_id: project.req_id,
    args: {
      status: project.status,
      data: project.data,
    },
  }

  return payload
}


export default useUpdateProject
