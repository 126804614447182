import { gql, useQuery, } from '@apollo/client'
import { useMemo, } from 'react'
import { useSelector, } from 'react-redux'


const ALL_RATING = gql`
  query StudentsAchievementsRating($input: AchievementsRatingInput!){
    studentsAchievementsRating(input: $input)
  }
`


const useAllRating = ({
  offset,
  limit,
  moderator_mode,
}: any) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const filtersValues = useSelector(state => state.port.filters.rating)
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const sort = useSelector(state => state.port.tableSort.rating)
  const newFilter: any = {}

  for (const [
    key,
    value,
  ] of Object.entries(filtersValues)) {
    value && (newFilter[key] = value)
  }


  const { data, loading, } = useQuery(ALL_RATING, {
    variables: {
      input: {
        pagination: {
          offset,
          limit,
        },
        filter: {
          full_name: newFilter.full_name,
          institute: newFilter.institute,
          eform: newFilter.education_form,
          elevel: newFilter.education_level?.length === 0 ? undefined : newFilter.education_level,
          namespec: newFilter.major,
          course: newFilter.course_number,
          direction: newFilter.direction ? [
            newFilter.direction,
          ] : undefined,
          type: newFilter.type ? [
            newFilter.type,
          ] : undefined,
          finalYearStudent: newFilter.finalYearStudent,
        },
        sort: [
          {
            column: sort.field,
            order: sort.order,
            nulls: 'last',
          },
        ],
        moderator_mode,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })



  const result = useMemo(() => {
    if (!loading && data) {
      const currentData = data.studentsAchievementsRating

      return {
        ...currentData,
      }
    } return {
      loading: true,
    }
  }, [
    data,
    loading,
  ])


  return result
}


export default useAllRating
