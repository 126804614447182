import {
  ADS_ROUTE,
  COURSEWORK_ROUTE,
  MyReferences_Route,
  MFC_MY_APPLICATIONS_ROUTE,
  NOT_FOUND_ROUTE,
  MY_EDUCATION_ROUTE,
  LEARNING_PATHWAY_ROUTE,
  DETAIL_PAGE_ROUTE,
  EDIT_DISCIPLINE_ROUTE,
  LIBRARY_ROUTE,
  LIBRARY_BOOK_DETAILS,
  LOGIN_ROUTE,
  MAIN_ROUTE,
  NEWS_ROUTE,
  OPVO_ROUTE,
  PROFILE_ROUTE,
  SCHEDULE_ROUTE,
  STUDY_JOURNAL_ROUTE,
  GET_REFERENCE_ROUTE,
  RECORD_BOOK_ROUTE,
  JOURNAL_INNER,
  SCOLARSHIP,
  PAYMENT,
  EFFECTIVE_CONTRACT_ROUTE,
  EFFECTIVE_CONTRACT_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE,
  EFFECTIVE_CONTRACT_STEPS_ROUTE,
  EFFECTIVE_CONTRACT_TEACHER_RATING_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE,
  EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE,
  EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
  PROJECTS_ALL,
  MY_PROJECTS,
  PROJECT,
  MY_APPLICATIONS,
  PROJECT_APPLY,
  MODERATE_APPLICATIONS,
  TASK,
  NOTIFICATIONS,
  PORTFOLIO,
  MODERATE_PORTFOLIO,
  PORTFOLIO_RATING,
  PORTFOLIO_TEMPLATES,
  PORTFOLIO_INDICATORS,
  PORTFOLIO_EDIT_INDICATOR,
  RATING_BY_ID,
  ANOUNCES,
  ANOUNCE_INNER,
  HIGH_EDUCATION,
  DPO_EDUCATION,
  LOGOUT,
  PRACTICE,
  MODERATE_ACHIVMENTS,
  PROJECTS_INTRODACTION,
  EDIT_TRACK_PAGE,
  MODERATE_NOTIFICATIONS,
  MFC_APL_CONSTRACTOR_ROUTE,
  PROJECT_SEMINAR,
  PROJECT_SEMINAR_BY,
  MY_DUTY_NOTES,
  GET_DUTY_NOTE,
  MFC_LOGS,
  SCHEDULE_TEACHER_ROUTE,
  MAJOR_ROUTE,
  SPECIALTIES_LIST,
  EDIT_MAJOR,
  TEST_ROUTE,
  STUDENT_MAJORS,
  MAJOR_DETAILS,
} from "@/shared/constants/routes";

import { UserRoles } from "@/shared/constants/userRoles";

import MyEducation from "@/legacy/components/MyEducation/HighEducation/RequiredDisciplines/MyEducation";
import LearningPathway from "@/legacy/components/MyEducation/HighEducation/LearningPathway/LearningPathway";
import DetailPage from "@/legacy/components/MyEducation/HighEducation/LearningPathway/DetailPage/DetailPage";
import EditDiscipline from "@/legacy/components/MyEducation/HighEducation/LearningPathway/EditDiscipline/EditDiscipline";
import Library from "@/legacy/components/Library/Library";
import Login from "@/features/Login/Login";
import Main from "@/legacy/components/Main/Main";
import Opvo from "@/legacy/components/Opvo/Opvo";
import Schedule from "@/pages/EducationalProcessPage/SchedulePage/StudentSchedulePage/ui/Schedule";
import ScheduleTeacher from "@/pages/EducationalProcessPage/SchedulePage/TeacherSchedulePage/ui/Schedule";
import CourseWork from "@/legacy/components/CourseWork/CourseWork";
import StudyJournal from "@/legacy/components/StudyJournal/StudyJournal";
import { News } from "@/pages/InformationPage/NewsPage";
import Profile from "@/legacy/components/Profile/Profile";
import Ads from "@/legacy/components/Ads/Ads";
import MyReferences from "@/legacy/components/Mfc/MyReferences/MyReferences";
import GetReference from "@/legacy/components/Mfc/GetReference/GetReference";
import RecordBook from "@/legacy/components/RecordBook/RecordBook";
import NotFound from "@/legacy/components/NotFound/NotFound";
import JournalInner from "@/legacy/components/StudyJournal/InnerJornal";
import Portfolio from "@/legacy/components/Portfolio/Portfolio";
import { CurrentNews } from "@/pages/InformationPage/NewsPage/ui";
import ScolarshipPage from "@/legacy/components/Finances/ScolarshipPage/ScolarshipPage";
import PaymentPage from "@/legacy/components/Finances/PaymentPage/PaymentPage";
import EffectiveContract from "@/legacy/components/EffectiveContract/EffectiveContract";
import Appeals from "@/legacy/components/EffectiveContract/Appeals";
import AddAchievement from "@/legacy/components/EffectiveContract/AddAchievement";
import ModerateAchievements from "@/legacy/components/EffectiveContract/ModerateAchievements";
import ModerateAppeals from "@/legacy/components/EffectiveContract/ModerateAppeals";
import AmsModerateAppeals from "@/legacy/components/EffectiveContract/AmsModerateAppeals";
import EffectiveContractStages from "@/legacy/components/EffectiveContract/EffectiveContractStages";
import TeacherRating from "@/legacy/components/EffectiveContract/TeacherRating/TeacherRating";
import AllProjects from "@/legacy/components/Projects/AllProjects/AllProjects";
import MyProjects from "@/legacy/components/Projects/MyProjects/MyProjects";
import ProjectPage from "@/legacy/components/Projects/ProjectPage/ProjectPage";
import MyApplications from "@/legacy/components/Projects/MyApplications/MyApplications";
import NewProject from "@/legacy/components/Projects/NewProject/NewProject";
import PAmoderApplications from "@/pages/PAmoderApplications/PAmoderApplicationsList";
import TaskPage from "@/legacy/components/Projects/TaskPage/TaskPage";
import Notifications from "@/legacy/components/Notifications/Notifications";
import ModeratePortfolio from "@/legacy/components/Portfolio/ModeratePortfolio/ModeratePortfolio";
import PortfolioRating from "@/legacy/components/Portfolio/PortfolioRating/PortfolioRating";
import RatingById from "@/legacy/components/Portfolio/RatingById/RatingById";
import ModeratePage from "@/legacy/components/Portfolio/ModeratePortfolio/ModeratePage/ModeratePage";
import { Anounces } from "@/pages/InformationPage/AnouncesPage";
import { AnnounceInner } from "@/pages/InformationPage/AnouncesPage/ui";
import HighEducation from "@/legacy/components/MyEducation/HighEducation/HighEducation";
import DPOEducation from "@/legacy/components/MyEducation/DPOEducation/DPOEducation";
import Logout from "@/features/Logout/Logout";
import Practice from "@/legacy/components/MyEducation/HighEducation/Practice/Practice";
import AmsEffectiveContract from "@/legacy/components/EffectiveContract/AmsEffectiveContract";
import ModerateThresholdValues from "@/legacy/components/EffectiveContract/ModerateThresholdValues/ModerateThresholdValues";
import PortfolioTemplates from "@/legacy/components/Portfolio/PortfolioTemplates/PortfolioTemplates";
import PortfolioIndicators from "@/legacy/components/Portfolio/PortfolioIndicators/PortfolioIndicators";
import PortfolioEditIndicator from "@/legacy/components/Portfolio/PortfolioEditIndicator/PortfolioEditIndicator";
import ProjectActivityIntroduction from "@/legacy/components/MyEducation/HighEducation/ProjectActivityIntroduction/ProjectActivityIntroduction";
import EditTrackPage from "@/legacy/components/MyEducation/HighEducation/LearningPathway/EditTrackPage/EditTrackPage";
import ModerateNotifications from "@/legacy/components/ModerateNotifications/ModerateNotifications";
import BookDetailsPage from "@/legacy/components/Library/BookDetailsPage/BookDetailsPage";
import MyApplicationsMFC from "@/legacy/components/Mfc/MyApplications/MyApplications";
import GetApplication from "@/legacy/components/Mfc/MyApplications/GetApplication/GetApplication";
import ProjectSeminar from "@/legacy/components/Projects/ProjectSeminar/ProjectSeminar";
import ProjectSeminarByGroup from "@/legacy/components/Projects/ProjectSeminar/pages/ProjectSeminarByGroup/ProjectSeminarByGroup";
import MyDutyNotes from "@/legacy/components/Mfc/MyDutyNotes/MyDutyNotes";
import GetDutyNote from "@/legacy/components/Mfc/MyDutyNotes/GetDutyNote/GetDutyNote";
import ActivityHistory from "@/legacy/components/Mfc/ActivityHistory/ActivityHistory";
import { SpeacialtyPage } from "@/pages/Majors/SpecialtyPage";
import { MajorPage } from "@/pages/Majors/MajorPage";
import { EditMajorPage } from "@/pages/Majors/EditMajorPage";
import { TestPage } from "@/pages/TestPage";
import { StudentsMajorPage } from "@/pages/Majors/StudentsMajorPage";
import { DetailsMajorPage } from "@/pages/Majors/StudentsMajorDetailsPage";

export const AuthRoutes = [
  {
    path: MAIN_ROUTE,
    Component: Main,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.DMS_MODER,
      UserRoles.UFPN_MODER,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_TWO_MODER,
      UserRoles.OSRV_MODER,
      UserRoles.URVO_ADMIN,
      UserRoles.AUP_ADMIN,
      UserRoles.DIRECTOR_INSTITUTE,
      UserRoles.DIRECTOR_FACULTY,
      UserRoles.AUP_PFU_MODER,
      UserRoles.AUP_PK_MODER,
      UserRoles.AUP_DMS_MODER,
      UserRoles.AUP_CK_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.NOTIFICATION_MODER,
      UserRoles.NOTIFICATION_ADMIN,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
      UserRoles.UNKNOWN,
    ],
  },
  {
    path: TEST_ROUTE,
    Component: TestPage,
    roles: [UserRoles.TESTER],
  },
  {
    path: SCHEDULE_ROUTE,
    Component: Schedule,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS],
  },
  {
    path: SCHEDULE_TEACHER_ROUTE,
    Component: ScheduleTeacher,
    roles: [UserRoles.TESTER, UserRoles.WORKER, UserRoles.TEACHER_GPH],
  },
  {
    path: MY_EDUCATION_ROUTE,
    Component: MyEducation,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.TESTER,
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: LEARNING_PATHWAY_ROUTE,
    Component: LearningPathway,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: SPECIALTIES_LIST,
    Component: SpeacialtyPage,
    roles: [
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.TESTER,
    ],
  },
  {
    path: MAJOR_ROUTE,
    Component: MajorPage,
    roles: [
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.TESTER,
    ],
  },
  {
    path: EDIT_MAJOR,
    Component: EditMajorPage,
    roles: [
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.TESTER,
    ],
  },
  {
    path: STUDENT_MAJORS,
    Component: StudentsMajorPage,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.TESTER],
  },
  {
    path: MAJOR_DETAILS,
    Component: DetailsMajorPage,
    roles: [UserRoles.STUDENT],
  },
  {
    path: PROJECTS_INTRODACTION,
    Component: ProjectActivityIntroduction,
    roles: [
      UserRoles.STUDENT,
      UserRoles.OSRV_MODER,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: DETAIL_PAGE_ROUTE,
    Component: DetailPage,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: EDIT_DISCIPLINE_ROUTE,
    Component: EditDiscipline,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: LIBRARY_ROUTE,
    Component: Library,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.TESTER],
  },
  {
    path: OPVO_ROUTE,
    Component: Opvo,
    roles: [UserRoles.TESTER],
  },
  {
    path: COURSEWORK_ROUTE,
    Component: CourseWork,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
    ],
  },
  {
    path: STUDY_JOURNAL_ROUTE,
    Component: StudyJournal,
    roles: [UserRoles.TESTER],
  },
  {
    path: NEWS_ROUTE,
    Component: News,
    roles: [
      UserRoles.STUDENT,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: `${NEWS_ROUTE}/:link`,
    Component: CurrentNews,
    roles: [
      UserRoles.STUDENT,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: PROFILE_ROUTE,
    Component: Profile,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: ADS_ROUTE,
    Component: Ads,
    roles: [
      UserRoles.STUDENT,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: MyReferences_Route,
    Component: MyReferences,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.WORKER,
      UserRoles.EMPLOYEE,
    ],
  },
  {
    path: GET_REFERENCE_ROUTE,
    Component: GetReference,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS],
  },
  {
    path: RECORD_BOOK_ROUTE,
    Component: RecordBook,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS],
  },
  {
    path: NOT_FOUND_ROUTE,
    Component: NotFound,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: JOURNAL_INNER,
    Component: JournalInner,
    roles: [UserRoles.TESTER],
  },
  {
    path: SCOLARSHIP,
    Component: ScolarshipPage,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS],
  },
  {
    path: PAYMENT,
    Component: PaymentPage,
    roles: [UserRoles.STUDENT],
  },
  {
    path: EFFECTIVE_CONTRACT_ROUTE,
    Component: EffectiveContract,
    roles: [UserRoles.WORKER, UserRoles.EMPLOYEE, UserRoles.TESTER],
  },
  {
    path: EFFECTIVE_CONTRACT_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [UserRoles.DIRECTOR_INSTITUTE, UserRoles.DIRECTOR_FACULTY],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENTS_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [
      UserRoles.AUP_ADMIN,
      UserRoles.AUP_PFU_MODER,
      UserRoles.AUP_PK_MODER,
      UserRoles.AUP_DMS_MODER,
      UserRoles.AUP_CK_MODER,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_AMS_ROUTE,
    Component: AmsEffectiveContract,
    roles: [
      UserRoles.AUP_ADMIN,
      UserRoles.AUP_PFU_MODER,
      UserRoles.AUP_PK_MODER,
      UserRoles.AUP_DMS_MODER,
      UserRoles.AUP_CK_MODER,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_ROUTE,
    Component: EffectiveContract,
    roles: [
      UserRoles.WORKER,
      UserRoles.EMPLOYEE,
      UserRoles.TESTER,
      UserRoles.DMS_MODER,
      UserRoles.UFPN_MODER,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_TWO_MODER,
      UserRoles.URVO_ADMIN,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_APPEALS_ROUTE,
    Component: Appeals,
    roles: [
      UserRoles.TESTER,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_ADMIN,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_APPEALS_AMS_ROUTE,
    Component: Appeals,
    roles: [UserRoles.AUP_ADMIN],
  },
  {
    path: EFFECTIVE_CONTRACT_ADD_ACHIEVEMENT_ROUTE,
    Component: AddAchievement,
    roles: [UserRoles.WORKER, UserRoles.EMPLOYEE, UserRoles.TESTER],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_ACHIEVEMENT_BY_ROUTE,
    Component: ModerateAchievements,
    roles: [
      UserRoles.UONID_MODER,
      UserRoles.DMS_MODER,
      UserRoles.UFPN_MODER,
      UserRoles.DM_MODER,
      UserRoles.TESTER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_TWO_MODER,
      UserRoles.URVO_ADMIN,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_ROUTE,
    Component: ModerateAppeals,
    roles: [
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.TESTER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.URVO_ADMIN,
    ],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_APPEAL_BY_AMS_ROUTE,
    Component: AmsModerateAppeals,
    roles: [UserRoles.AUP_ADMIN],
  },
  {
    path: EFFECTIVE_CONTRACT_STEPS_ROUTE,
    Component: EffectiveContractStages,
    roles: [UserRoles.URVO_ADMIN],
  },
  {
    path: EFFECTIVE_CONTRACT_TEACHER_RATING_ROUTE,
    Component: TeacherRating,
    roles: [UserRoles.WORKER, UserRoles.EMPLOYEE, UserRoles.TESTER],
  },
  {
    path: EFFECTIVE_CONTRACT_MODERATE_THRESHOLD_VALUES_ROUTE,
    Component: ModerateThresholdValues,
    roles: [UserRoles.AUP_ADMIN],
  },
  {
    path: PROJECTS_ALL,
    Component: AllProjects,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
    ],
  },
  {
    path: MY_PROJECTS,
    Component: MyProjects,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.TESTER,
    ],
  },
  {
    path: PROJECT,
    Component: ProjectPage,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
    ],
  },
  {
    path: PROJECT_SEMINAR,
    Component: ProjectSeminar,
    roles: [UserRoles.WORKER, UserRoles.TEACHER_GPH],
  },
  {
    path: PROJECT_SEMINAR_BY,
    Component: ProjectSeminarByGroup,
    roles: [UserRoles.WORKER, UserRoles.TEACHER_GPH],
  },
  {
    path: MY_APPLICATIONS,
    Component: MyApplications,
    roles: [UserRoles.WORKER, UserRoles.TEACHER_GPH, UserRoles.TESTER],
  },
  {
    path: PROJECT_APPLY,
    Component: NewProject,
    roles: [
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
    ],
  },
  {
    path: MODERATE_APPLICATIONS,
    Component: PAmoderApplications,
    roles: [UserRoles.PA_MODER, UserRoles.TESTER],
  },
  {
    path: TASK,
    Component: TaskPage,
    roles: [
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.PA_MODER,
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.TESTER,
    ],
  },
  {
    path: NOTIFICATIONS,
    Component: Notifications,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.PA_MODER,
      UserRoles.TESTER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
      UserRoles.OSRV_MODER,
    ],
  },
  {
    path: MODERATE_NOTIFICATIONS,
    Component: ModerateNotifications,
    roles: [UserRoles.NOTIFICATION_MODER, UserRoles.NOTIFICATION_ADMIN],
  },
  {
    path: PORTFOLIO,
    Component: Portfolio,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS],
  },
  {
    path: MODERATE_PORTFOLIO,
    Component: ModeratePortfolio,
    roles: [
      UserRoles.URTOS_MODER,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: PORTFOLIO_RATING,
    Component: PortfolioRating,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.URTOS_MODER],
  },
  {
    path: PORTFOLIO_TEMPLATES,
    Component: PortfolioTemplates,
    roles: [UserRoles.URTOS_MODER],
  },
  {
    path: PORTFOLIO_INDICATORS,
    Component: PortfolioIndicators,
    roles: [UserRoles.URTOS_MODER],
  },
  {
    path: PORTFOLIO_EDIT_INDICATOR,
    Component: PortfolioEditIndicator,
    roles: [UserRoles.URTOS_MODER],
  },
  {
    path: RATING_BY_ID,
    Component: RatingById,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.URTOS_MODER],
  },
  {
    path: MODERATE_ACHIVMENTS,
    Component: ModeratePage,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.URTOS_MODER,
      UserRoles.PORTFOLIO_MFC_MODER,
      UserRoles.PORTFOLIO_KURATOR,
    ],
  },
  {
    path: ANOUNCES,
    Component: Anounces,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
    ],
  },
  {
    path: ANOUNCE_INNER,
    Component: AnnounceInner,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.TESTER,
      UserRoles.PA_MODER,
      UserRoles.WORKER,
      UserRoles.TEACHER_GPH,
      UserRoles.UONID_MODER,
      UserRoles.DM_MODER,
      UserRoles.UKP_MODER,
      UserRoles.TPD_MODER,
      UserRoles.URTOS_MODER,
      UserRoles.URVO_MODER,
      UserRoles.SELFSIGNUP,
      UserRoles.EMPLOYEE,
    ],
  },
  {
    path: HIGH_EDUCATION,
    Component: HighEducation,
    roles: [
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
      UserRoles.OSRV_MODER,
      UserRoles.PROSMOTR_MOE_OBUCHENIE,
    ],
  },
  {
    path: EDIT_TRACK_PAGE,
    Component: EditTrackPage,
    roles: [UserRoles.OSRV_MODER, UserRoles.PROSMOTR_MOE_OBUCHENIE],
  },
  {
    path: DPO_EDUCATION,
    Component: DPOEducation,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.OSRV_MODER],
  },
  {
    path: PRACTICE,
    Component: Practice,
    roles: [UserRoles.STUDENT, UserRoles.STUDENT_RAKUS, UserRoles.OSRV_MODER],
  },
  {
    path: LIBRARY_BOOK_DETAILS,
    Component: BookDetailsPage,
    roles: [UserRoles.STUDENT],
  },
  {
    path: MFC_MY_APPLICATIONS_ROUTE,
    Component: MyApplicationsMFC,
    roles: [
      UserRoles.EMPLOYEE,
      UserRoles.WORKER,
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
    ],
  },
  {
    path: MFC_APL_CONSTRACTOR_ROUTE,
    Component: GetApplication,
    roles: [
      UserRoles.EMPLOYEE,
      UserRoles.WORKER,
      UserRoles.STUDENT,
      UserRoles.STUDENT_RAKUS,
    ],
  },
  {
    path: MY_DUTY_NOTES,
    Component: MyDutyNotes,
    roles: [UserRoles.EMPLOYEE, UserRoles.WORKER],
  },
  {
    path: GET_DUTY_NOTE,
    Component: GetDutyNote,
    roles: [UserRoles.EMPLOYEE, UserRoles.WORKER],
  },
  {
    path: MFC_LOGS,
    Component: ActivityHistory,
    roles: [UserRoles.EMPLOYEE, UserRoles.WORKER],
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
  {
    path: LOGOUT,
    Component: Logout,
  },
];
